import React, { createContext, useContext } from "react";
import axios from "axios";

export const ApiContext = createContext({
    register: () => {},
    login: () => {},
    me: () => {},
    forgotPassword: () => {},
    resetPassword: (linkId, password) => {},
    getLectures: () => {},
    createLecture: (lecture) => {},
    saveLecture: (lecture) => {},
    deleteLecture: (lecture) => {},
    createExamGenRequest: (examGenRequest) => {},
    createExamGenRequestTrial: (lectureText) => {},
    getExamGenRequest: () => {},
    getExamGenRequests: () => {},
    getQuestionBank: () => {},
    getQuestionsForDocument: (document) => {},
    saveQuestion: (document, question) => {},
    createWebsiteInquiry: (inquiry) => {},
    createMarketingEmail: (email_address) => {},
    getOrder: () => {},
    createOrder: () => {},
    completeOrder: (order) => {},
    verifyLink: (linkId) => {}
});
export const ApiProvider = ({ children}) => {
    const register = async(firstName, lastName, emailAddress, password, institution) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
                first_name: firstName,
                last_name: lastName,
                email_address: emailAddress,
                password: password,
                institution: institution
            });

            localStorage.setItem("access_token", response.data.access_token);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const login = async(emailAddress, password) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                email_address: emailAddress,
                password: password
            });

            localStorage.setItem("access_token", response.data.access_token);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const me = async() => {
        try {
            console.log(localStorage.getItem('access_token'));
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/me`);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const forgotPassword = async(email_address) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/forgot_password`, {
                email_address
            });

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const resetPassword = async(linkId, password) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/reset_password/${linkId}`, {
                password
            });

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const getLectures = async() => {
        try {
            console.log(localStorage.getItem('access_token'));
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents`);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const saveLecture = async(lecture) => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/documents/${lecture.id}`, lecture);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const createLecture = async(lecture) => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/documents`, lecture);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const deleteLecture = async(lecture) => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/documents/${lecture.id}`);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const createExamGenRequest = async(examGenRequest) => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/examGenRequests`, examGenRequest);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const getExamGenRequests = async() => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/examGenRequests`);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const getExamGenRequest = async(examGenRequestId) => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/examGenRequests/${examGenRequestId}`);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const getQuestionBank = async(page, pageSize) => {
        try {
            console.log(localStorage.getItem('access_token'));
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/questionBank?page=${page}&page_size=${pageSize}`);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const getQuestionsForDocument = async(document) => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/${document._id}/questions`);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const saveQuestion = async(document, question) => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/documents/${document._id}/questions/${question._id}`, question);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const createExamGenRequestTrial = async(text) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/examGenRequestTrials`, {
                text
            });

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const createWebsiteInquiry = async(inquiry) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/webSiteInquiries`, {
                name: inquiry.name,
                institution: inquiry.institution,
                email_address: inquiry.email_address,
                message: inquiry.message
            });

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const createMarketingEmail = async(email_address) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/marketingEmails`, {
                email_address
            });

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const getOrder = async(id) => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders/${id}`);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const createOrder = async(product) => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/orders`, {
                product
            });

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const completeOrder = async(orderId) => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/orders/${orderId}/complete`);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const cancelOrder = async(orderId) => {
        try {
            axios.defaults.headers.common["x-access-token"] = `${localStorage.getItem('access_token')}`;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/orders/${orderId}/cancel`);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const verifyLink = async(linkId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/links/${linkId}/verify`);

            return response.data;
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    return (
        <ApiContext.Provider
            value={{
                register,
                login,
                me,
                forgotPassword,
                resetPassword,
                getLectures,
                createLecture,
                saveLecture,
                deleteLecture,
                createExamGenRequest,
                getExamGenRequests,
                getExamGenRequest,
                getQuestionBank,
                getQuestionsForDocument,
                saveQuestion,
                createExamGenRequestTrial,
                createWebsiteInquiry,
                createMarketingEmail,
                getOrder,
                createOrder,
                completeOrder,
                verifyLink,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
};
export const useApi = () => {
    const context = useContext(ApiContext);

    if (!context) {
        throw new Error("useApi must be used within an ApiProvider");
    }

    return context;
};
