import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";

import {
    Box,
    Typography
} from "@mui/material"
import { Unstable_Grid2 as Grid } from '@mui/material'; // Grid version
import {styled} from "@mui/material/styles";

import ChatGPTExamsImage from '../../images/ChatGPTExams.png';

import {useApi} from "../contexts/ApiProvider";
import {usePubNub} from "../contexts/PubNubProvider";

import MarketingView from "./component/MarketingView";
import ExamGenTrialView from "./component/ExamGenTrialView";
import ContactUsForm from "./component/ContactUsForm";
import Footer from "./component/Footer";
import FAQs from "./component/FAQs";
import Pricing from "./component/Pricing";
import Header from "./component/Header";
import Top from "./component/Top";

const SectionGrid = styled(Grid)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        paddingLeft: "20px",
        paddingRight: "20px"
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: "100px",
        paddingRight: "100px"
    },
}));
const ToolsDescriptionWrapperBox = styled(Box)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        marginBottom: "60px",
    },
    [theme.breakpoints.up('md')]: {
        padding: "60px",
    },
}));
const HomePage = () => {
    const [emailAddress, setEmailAddress] = useState("");
    const [examGenRequestTrial, setExamGenRequestTrial] = useState(undefined);
    const [generatedQuestions, setGeneratedQuestions] = useState([]);
    const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);
    const [emailInquiryState, setEmailInquiryState] = useState({
        isValid: true,
        validationMessage: "Please enter a valid email address",
        isError: false,
        isEnabled: true,
        isSent: false,
        errorMessage: ""
    });
    const [contactUsState, setContactUsState] = useState({
        isInValid: false,
        inValidFields: {
            name: false,
            emailAddress: false,
            institution: false,
            message: false
        },
        validationMessages: {
            name: "Name is required",
            emailAddress: "Please enter a valid email address",
            institution: "College or Institution is required",
            message: "Please include a short message"
        },
        validationMessage: "Please correct the issues below and submit again.",
        successMessage: "Thank you for your comment.  We'll follow up shortly.",
        isError: false,
        isEnabled: true,
        isSent: false,
        errorMessage: "An error occurred"
    });

   const { pubNub } = usePubNub();
   const { createExamGenRequestTrial, createMarketingEmail, createWebsiteInquiry } = useApi();

   useEffect(() => {
       ReactGA.send({ hitType: "pageview", page: "/" });
   }, []);
    useEffect(() => {
        if(!pubNub) { return }

        const listener = {
            status: (statusEvent) => {
                if (statusEvent.category === "PNConnectedCategory") {
                    console.log("Connected");
                }
            },
            message: (messageEvent) => {
                console.log(messageEvent.message);

                switch(messageEvent.message.type) {
                    case "question": {
                        console.log("PROCESSING QUESTION", messageEvent.message.question);

                        const question = messageEvent.message.question;

                        setGeneratedQuestions(prev => [...prev, question]);

                        break;
                    }
                    case "finished": {
                        setIsGeneratingQuestions(false);

                        break;
                    }
                    default: {
                        console.log(`Not sure how to handle ${messageEvent.message.type}`);
                    }
                }
            },
            presence: (presenceEvent) => {
                // handle presence
            }
        };
        pubNub.addListener(listener);
    }, [pubNub]);
    useEffect(() => {
        if(!examGenRequestTrial) { return; }

        pubNub.subscribe({
            channels: [`exam_gen_trial_questions_${examGenRequestTrial.id}`]
        });
    }, [examGenRequestTrial])
   const generateQuestionsClicked = async(lectureText) => {
       if(!lectureText) { return; }

       try {
           ReactGA.event({
               category: "Landing Page",
               action: "Generate Sample Questions Clicked",
           });

           setIsGeneratingQuestions(true);

           const examGenRequestTrial = await createExamGenRequestTrial(lectureText);

           setExamGenRequestTrial(examGenRequestTrial);
       } catch(err) {
           setIsGeneratingQuestions(false);

           console.log(err);
       }
   }
   const updateEmailInquiryAddress = (e) => {
        setEmailAddress(e.target.value);
   }
   const emailInquiryClicked = async(emailAddress) => {
        try {
            if(!validateEmail(emailAddress)) {
                setEmailInquiryState(prev => Object.assign({}, prev, {
                    isValid: false,
                    isError: false,
                }));

                return;
            }

            ReactGA.event({
                category: "Landing Page",
                action: "Website Inquiry Clicked",
            });

            await createMarketingEmail(emailAddress)

            setEmailInquiryState(prev => Object.assign({}, prev, {
                isValid: true,
                isSent: true,
                isEnabled: false,
                isError: false,
                errorMessage: ""
            }));
        } catch(err) {
            console.error(err);

            setEmailInquiryState(prev => Object.assign({}, prev, {
                isValid: true,
                isSent: false,
                isEnabled: false,
                isError: true,
                errorMessage: "Sorry, an error occurred.  Please try again."
            }));
        }
   }
   const contactUsSendClicked = async(contact) => {
        try {
            const isNameValid = contact.name.length > 0;
            const isInstitutionValid = contact.institution.length > 0;
            const isEmailAddressValid = validateEmail(contact.email_address);
            const isMessageValid = contact.message.length > 0;

            const isValid = isNameValid && isInstitutionValid && isEmailAddressValid && isMessageValid;

            if(!isValid) {
                setContactUsState(prev => Object.assign({}, prev, {
                    isInValid: true,
                    isError: false,
                    isSent: false,
                    isSuccess: false,
                    inValidFields: {
                        name: !isNameValid,
                        emailAddress: !isEmailAddressValid,
                        institution: !isInstitutionValid,
                        message: !isMessageValid
                    }
                }));
                return;
            }

            ReactGA.event({
                category: "Landing Page",
                action: "Contact Us Clicked",
            });

            await createWebsiteInquiry(contact);

            setContactUsState(prev => Object.assign({}, prev, {
                isInValid: false,
                inValidFields: {
                    name: false,
                    emailAddress: false,
                    institution: false,
                    message: false
                },
                isSent: true,
                isError: false,
                isSuccess: true,
            }));
            // setContact({
            //     name: "",
            //     institution: "",
            //     email_address: "",
            //     message: ""
            // });
        } catch(err) {
            console.error(err);

            setContactUsState(prev => Object.assign({}, prev, {
                isInValid: false,
                inValidFields: {
                    name: false,
                    emailAddress: false,
                    institution: false,
                    message: false
                },
                isSent: false,
                isError: true,
                isSuccess: false,
                errorMessage: "Sorry, an error occurred.  Please try again."
            }));
        }

   }
    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(email);
    }
  return (
      <Box>
      <a id={"/"}></a>
      <Header />
          <Top />
          <MarketingView emailInquiryState={emailInquiryState} sendClicked={emailInquiryClicked} emailAddress={emailAddress} emailAddressChanged={updateEmailInquiryAddress} />
          <Box style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: "40px",
              paddingBottom: "40px",
              backgroundColor: "rgba(217, 217, 217, 0.25)"
          }}>
              <a id={"tools"}></a>
              <Typography variant={"h3"}>Our Tools</Typography>
                  <SectionGrid container spacing={0} style={{
                      justifyContent: "center",
                      alignItems: "center",
                  }}>
                      <Grid xs={12} md={6}>
                          <Box style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                          }}>
                      <img src={ChatGPTExamsImage} style={{
                          maxWidth: "100%"
                      }} />
                    </Box>
                  </Grid>
                  <Grid xs={12} md={6}>
                      <ToolsDescriptionWrapperBox>
                          <Box style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                          }}>
                              <Typography variant={"h5"} style={{
                                  marginBottom: "8px"
                              }}>Exam Question Generation using ChatGPT</Typography>
                              <Typography variant={"body1"} style={{
                                  lineHeight: 1.5,
                                  marginRight: "40px"
                              }}>Save time and take the headache out of exam preparation by building a question bank directly from your lectures and other classroom material.  Our ExamGen tool uses ChatGPT to understand your lecture material and write multiple choice, short and long answer questions.</Typography>
                          </Box>
                      </ToolsDescriptionWrapperBox>
                  </Grid>
                  </SectionGrid>
              <ExamGenTrialView generateQuestions={generateQuestionsClicked} generatedQuestions={generatedQuestions} isGeneratingQuestions={isGeneratingQuestions} />
          </Box>
          <a id={"pricing"}></a>
          <Pricing />
          <a id={"faqs"}></a>
          <FAQs />
          <a id={"contactus"}></a>
          <ContactUsForm sendClicked={contactUsSendClicked} contactUsState={contactUsState} />
          <Footer />
      </Box>
  );
}
HomePage.propTypes = {
  signUp: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

export default HomePage;
