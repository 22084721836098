import React, {useState} from "react";
import {Box, Button, TextField, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";

const Root = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingTop: "40px",
    paddingBottom: "40px",
}));
const TitleWrapper = styled(Box)(({theme}) => ({
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: "80px",
        paddingRight: "80px",
    },
}));
const OuterWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: "rgba(217, 217, 217, 0.33)",
    marginTop: "24px",
    [theme.breakpoints.down('md')]: {
        marginLeft: "24px",
        marginRight: "24px",
    }
}));
const Wrapper = styled(Box)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        paddingLeft: "24px",
        paddingRight: "24px",
        width: "calc(100vw - 96px)"
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: "64px",
        paddingRight: "64px",
        width: "840px",
    },
}));
const ContactUsForm = (props) => {
    const [contact, setContact] = useState({
        name: "",
        institution: "",
        email_address: "",
        message: ""
    });
    const { contactUsState, sendClicked } = props;

    return (<Root>
            <TitleWrapper>
                <Typography variant={"h3"} style={{
                    marginBottom: "40px"
                }}>Contact Us</Typography>
                <Typography variant={"h6"}>Have any questions or want to learn more? We’d love to chat.</Typography>
            </TitleWrapper>
        <OuterWrapper>
            <Wrapper>
            {contactUsState.isSent && <Typography variant={"success"}>{contactUsState.successMessage}</Typography>}
            {contactUsState.isError && <Typography variant={"error"}>{contactUsState.errorMessage}</Typography>}
            {contactUsState.isInValid && <Typography variant={"error"}>{contactUsState.validationMessage}</Typography>}
            <Box style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: "24px",
                marginBottom: "24px",
            }}>
                <Typography variant={"label"}
                            style={{
                                marginBottom: "8px"
                            }}>Your Name:</Typography>
                <TextField  placeholder={"Enter Your Name"} value={contact.name} fullWidth
                            onChange={(e) => setContact(prev => Object.assign({}, prev, { name: e.target.value }))} />
                {contactUsState.inValidFields.name && <Typography variant={"error"}>{contactUsState.validationMessages.name}</Typography>}
            </Box>
            <Box style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginBottom: "24px",
            }}>
                <Typography variant={"label"} style={{
                    marginBottom: "8px"
                }}>Your College/Institution:</Typography>
                <TextField placeholder={"Enter Your College or Institution Name"} value={contact.institution}  fullWidth
                           onChange={(e) => setContact(prev => Object.assign({}, prev, { institution: e.target.value }))} />
                {contactUsState.inValidFields.institution && <Typography variant={"error"}>{contactUsState.validationMessages.institution}</Typography>}
            </Box>
            <Box style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginBottom: "24px",
            }}>
                <Typography variant={"label"} style={{
                    marginBottom: "8px"
                }}>Your Email:</Typography>
                <TextField placeholder={"Enter Your Email Address"} value={contact.email_address}  fullWidth
                           onChange={(e) => setContact(prev => Object.assign({}, prev, { email_address: e.target.value }))} />
                {contactUsState.inValidFields.emailAddress && <Typography variant={"error"}>{contactUsState.validationMessages.emailAddress}</Typography>}
            </Box>
            <Box style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginBottom: "24px",
            }}>
                <Typography variant={"label"} style={{
                    marginBottom: "8px"
                }}>Your Thoughts:</Typography>
                <TextField placeholder={"Tell us your thoughts or comments"} value={contact.message}  fullWidth multiline rows={6}
                           onChange={(e) => setContact(prev => Object.assign({}, prev, { message: e.target.value }))} />
                {contactUsState.inValidFields.message && <Typography variant={"error"}>{contactUsState.validationMessages.message}</Typography>}
            </Box>
            <Button variant={"contained"}
                    onClick={() => sendClicked(contact)}
                    style={{
                        backgroundColor: "#F55050",
                        width: "120px"
                    }}>Send</Button>
        </Wrapper>
        </OuterWrapper>
    </Root>);
}

export default ContactUsForm;