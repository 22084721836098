import React from "react";
import PropTypes from "prop-types";

import {Box, Paper} from "@mui/material";

const AuthBox = (props) => {
  const { children } = props;

  return (
    <Box style={{
      margin: "20px auto",
      textAlign: "center"
    }}>
      <Paper style={{
        background: "#ffffff",
        padding: "20px",
      }}>{children}</Paper>
    </Box>
  );
};
AuthBox.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.object.isRequired,
};
AuthBox.defaultProps = {
  children: {},
  classes: {},
};

export default AuthBox;
