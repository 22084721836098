import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import LoginForm from "./LoginForm";
import AuthBox from "./AuthBox";
import AuthLayout from "./AuthLayout";

import { Link, Typography } from "@mui/material";
import {useAuth} from "../contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import handleError from "../common/ErrorHandler";
import {useFlashMessage} from "../contexts/FlashMessageProvider";

const LoginPage = (props) => {
  console.log("LOGIN")
  const navigate = useNavigate();
  const { showErrorMessage } = useFlashMessage();
  const { loginUser } = useAuth();
  const [loggingIn, setLoginningIn] = useState(false);
  const [credentials, setCredentials] = useState({
    email_address: "",
    password: "",
  });
  const [loginState, setLoginState] = useState({
    isInValid: false,
    inValidFields: {
      emailAddress: false,
      password: false,
    },
    validationMessages: {
      emailAddress: "Please enter a valid email address",
      password: "Password is required",
    },
    validationMessage: "Please correct the issues below and submit again.",
    isError: false,
    isEnabled: true,
    isSent: false,
    errorMessage: "An error occurred"
  });

  function updateCredentialsState(event) {
    const field = event.target.name;

    let updatedCredentials = Object.assign({}, credentials);
    updatedCredentials[field] = event.target.value.trim();

    setCredentials(updatedCredentials);
  }
  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  }
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/login" });
  }, []);
  const loginClicked = async() => {
    try {

      setLoginningIn(true);
      let isEmailValid = true;
      let isPasswordValid = true;

      if(!validateEmail(credentials.email_address)) {
        isEmailValid = false;
      }
      if(credentials.password.length === 0) {
        isPasswordValid = false;
      }
      const isValid = isEmailValid && isPasswordValid;

      if(!isValid) {
        setLoginState(prev => Object.assign({}, prev, {
          isInValid: true,
          inValidFields: {
            emailAddress: !isEmailValid,
            password: !isPasswordValid,
          },
          isSent: false,
          isError: false,
          isSuccess: false,
          errorMessage: ""
        }));

        return;
      }
      ReactGA.event({
        category: "Auth",
        action: "Login Clicked",
      });

      await loginUser(credentials.email_address, credentials.password);

      navigate("/lectures");

      setLoginState(prev => Object.assign({}, prev, {
        isInValid: false,
        inValidFields: {
          emailAddress: false,
          password: false,
        },
        isSent: true,
        isError: false,
        isSuccess: true,
        errorMessage: ""
      }));
    } catch(err) {
      const message = handleError(err);

      showErrorMessage(message);

      setLoginState(prev => Object.assign({}, prev, {
        isInValid: false,
        inValidFields: {
          emailAddress: false,
          password: false,
        },
        isSent: false,
        isError: true,
        isSuccess: false,
        errorMessage: message
      }));
    } finally {
      setLoginningIn(false);
    }
  }

  return (
    <AuthLayout heading={"Sign In"}>
      <AuthBox>
        <LoginForm
          saving={loggingIn}
          credentials={credentials}
          onChange={updateCredentialsState}
          onAuthenticate={loginClicked}
          loginState={loginState}
        />
      </AuthBox>
      <Typography variant={"body1"} component={"h2"}>
        {"Don't have an account? "}
        <Link href="/pricing">Register</Link>
      </Typography>
    </AuthLayout>
  );
};
LoginPage.defaultProps = {
  history: {},
  match: {},
  location: {},
};
LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default LoginPage;
