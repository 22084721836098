import React from "react";
import {Box, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import HeaderImage from "../../../images/HeaderImage.png";
import {styled} from "@mui/material/styles";

const TextWrapper = styled(Box)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        padding: "0px 20px",
        marginBottom: "40px"
    },
    [theme.breakpoints.up('md')]: {
        padding: "40px"
    },
}));
const ImageWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    [theme.breakpoints.down('md')]: {
        width: "350px"
    },
    [theme.breakpoints.up('md')]: {
        height: "calc(100vh - 240px)"
    },
}));
const Image = styled('img')(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        width: "calc(100vw - 40px)"
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: "600px"
    },
}));
const Top = () => {
    return (<Grid container spacing={0} style={{
        justifyContent: "center",
        alignItems: "center",
    }}>
        <Grid xs={12} md={6} order={{ xs:2, sm: 1 }}>
            <TextWrapper>
                <Box style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <Typography variant={"h2"} style={{
                        textTransform: "uppercase",
                    }}>M</Typography>
                    <Typography variant={"h2"} style={{
                        textTransform: "uppercase",
                        marginRight: "0.15em"
                    }}>y</Typography>
                    <Typography variant={"h2"} style={{
                        textTransform: "uppercase",
                        fontWeight: "500",
                        color: "#F55050"
                    }}>Professo</Typography>
                    <Typography variant={"h2"} style={{
                        textTransform: "uppercase",
                        marginRight: "0.15em",
                        fontWeight: "500",
                        color: "#F55050"
                    }}>r</Typography>
                    <Typography variant={"h2"} style={{
                        textTransform: "uppercase",
                    }}>AI</Typography>
                </Box>
                <Typography variant={"h4"}>ChatGPT Tools for College Professors</Typography>
            </TextWrapper>
        </Grid>
        <Grid xs={12} md={6} order={{ xs: 1, sm: 2 }}>
            <ImageWrapper>
                <Image src={HeaderImage} />
            </ImageWrapper>
        </Grid>
    </Grid>);
}
export default Top;