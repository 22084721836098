import React, { useEffect } from "react";
import PropTypes from "prop-types";

import {useNavigate, redirect, useParams} from "react-router-dom";
import {useApi} from "../contexts/ApiProvider";
import {useAuth} from "../contexts/AuthProvider";
import {useFlashMessage} from "../contexts/FlashMessageProvider";
import handleError from "../common/ErrorHandler";

const OrderComplete = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { completeOrder } = useApi();
    const { refreshUser } = useAuth();
    const { showSuccessMessage, showErrorMessage } = useFlashMessage();

    useEffect(() => {
        if(!id) { return; }

        const updateOrder = async(orderId) => {
            try {
                await completeOrder(orderId);
                await refreshUser();

                navigate("/lectures");

                showSuccessMessage("Your order was successfully completed.")
            } catch(err) {
                const message = handleError(err);

                navigate("/");

                showErrorMessage(message);
            }
        }

        updateOrder(id);
    }, [id]);

    return (<></>);
}
export default OrderComplete;