import React from 'react';
import {Avatar, Box, Button, Divider, IconButton, Menu, MenuItem, Link, ListItemIcon, Tooltip, Typography} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from "@mui/icons-material/Star";
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Logo from "../images/logo.png";

import {useAuth} from "./contexts/AuthProvider";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";

const UpgradeButton = styled(Button)(({theme}) => ({
    backgroundColor: "#B0E0E6",
    color: "#333399",
    '&:hover': {
        backgroundColor: "#FFA500",
        color: "#FFFFFF"
    }
}));
const Header = () => {
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "12px",
            paddingBottom: "12px",
        }}>
            <Box style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%"
            }}>
            <img src={Logo} style={{
                height: "56px",
                marginRight: "12px"
            }} />
            <Box style={{
                display: "flex",
                flexDirection: "row",
            }}>
                <Typography variant={"h2"} style={{
                    textTransform: "uppercase",
                    fontSize: "36px"
                }}>M</Typography>
                <Typography variant={"h2"} style={{
                    textTransform: "uppercase",
                    marginRight: "0.15em",
                    fontSize: "36px"
                }}>y</Typography>
                <Typography variant={"h2"} style={{
                    textTransform: "uppercase",
                    fontWeight: "500",
                    color: "#F55050",
                    fontSize: "36px"
                }}>Professo</Typography>
                <Typography variant={"h2"} style={{
                    textTransform: "uppercase",
                    marginRight: "0.15em",
                    fontWeight: "500",
                    color: "#F55050",
                    fontSize: "36px"
                }}>r</Typography>
                <Typography variant={"h2"} style={{
                    textTransform: "uppercase",
                    fontSize: "36px"
                }}>AI</Typography>
            </Box>
            <Box style={{
                flexGrow: 1
            }} />
            <Box style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            }}>
                {currentUser.account_type === "Free" && <Box style={{
                    marginRight: "24px"
                }}>
                    <UpgradeButton variant={"contained"} component={Link} href={"/upgrade"}
                    startIcon={<StarIcon />}
                    >Upgrade</UpgradeButton>
                </Box>}
            </Box>
            </Box>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{ width: 32, height: 32 }} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <Avatar />
                    <Box style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        marginRight: "12px"
                    }}>
                        <Typography variant={"body1"} style={{
                            marginBottom: "0px"
                        }}>{currentUser.first_name} {currentUser.last_name}</Typography>
                        <Typography variant={"body2"}>{currentUser.institution}</Typography>
                    </Box>
                </MenuItem>
                <Divider />
                <MenuItem onClick={async() => {
                    try {
                        await logout();

                        navigate("/login");
                    } catch(err) {
                        console.log(err);
                    }
                }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Box>
    );
}

export default Header;