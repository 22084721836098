import React, {useState} from "react";
import PropTypes from "prop-types";
import {Box, Button, CircularProgress, TextField, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import {styled} from "@mui/material/styles";

const Root = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
        width: "100%",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    [theme.breakpoints.up('md')]: {
        width: "80%",
    },
}));

const ExamGenTrialView = (props) => {
    const { generateQuestions, generatedQuestions, isGeneratingQuestions } = props;
    const [lectureText, setLectureText] = useState("");
    const [selectedGeneratedQuestion, setSelectedGeneratedQuestion] = useState(0);
    const letterArray = ["A", "B", "C", "D", "E"];

    const onLectureChanged = (event) => {
        setLectureText(event.target.value);
    }
    const generateQuestionsClicked = async() => {
        if(!lectureText) { return; }

        generateQuestions(lectureText);
    }
    return (<Root>
        <Typography variant={"h6"} style={{
            marginBottom: "28px"
        }}>Try it out</Typography>
        <Typography variant={"body1"} style={{
            marginBottom: "56px"
        }}>Copy and paste a lecture (1000 words max) and we’ll generate a set of questions from it.</Typography>
        <Box style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "40px",
            width: "100%"
        }}>
            <Grid container spacing={0} style={{
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Grid xs={12} md={6}>
                    <Box style={{
                        marginLeft: "20px",
                        marginRight: "20px",
                        background: "rgba(217, 217, 217, 0.5)",
                        border: "1px solid rgba(0, 0, 0, 0.25)"
                    }}>
                        <TextField fullWidth multiline rows={24} placeholder={"Copy and paste your lecture here"} value={lectureText} onChange={onLectureChanged}
                           InputProps={{
                               style: {
                                   fontSize: "14px",
                                   border: "none",
                               }
                            }} />
                    </Box>
                </Grid>
                <Grid xs={12} md={6}>
                    <Box  style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        minHeight: "500px"
                    }}>
                        <Box style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flexGrow: 1,
                            minHeight: "120px",
                            paddingLeft: "24px",
                            paddingRight: "24px",
                        }}>
                            {isGeneratingQuestions && <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>}
                            {!isGeneratingQuestions && generatedQuestions.length === 0 &&
                                <Typography variant={"body1"}>Click “Generate Questions” to start.</Typography>
                            }
                            {!isGeneratingQuestions && generatedQuestions.length > 0 && <Box style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                textAlign: "left"
                            }}>
                                <Typography variant={"body1"} style={{
                                    marginBottom: "8px"
                                }}>{`${selectedGeneratedQuestion+1}. ${generatedQuestions[selectedGeneratedQuestion].question}`}</Typography>
                                {generatedQuestions[selectedGeneratedQuestion].possible_answers && <Box>
                                    {generatedQuestions[selectedGeneratedQuestion].possible_answers.map((possible_answer, i) => {
                                        return (
                                            <Typography variant={"body1"}>{`${letterArray[i]}. ${possible_answer}`}</Typography>
                                        );
                                    })}
                                </Box>}
                            </Box>}
                        </Box>
                        <Box style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "24px"
                        }}>
                            <Button variant={"contained"}
                                    disabled={generatedQuestions.length < 1}
                                    onClick={() => {
                                        setSelectedGeneratedQuestion(0);
                                    }}
                                    style={{
                                        backgroundColor: "#F55050",
                                        minWidth: "48px",
                                        height: "48px",
                                        borderRadius: "24px",
                                        marginRight: "8px",
                                        padding: "0px !important",
                                    }}>1</Button>
                            <Button variant={"contained"}
                                    disabled={generatedQuestions.length < 2}
                                    onClick={() => {
                                        setSelectedGeneratedQuestion(1);
                                    }}
                                    style={{
                                        backgroundColor: "#F55050",
                                        minWidth: "48px",
                                        height: "48px",
                                        borderRadius: "24px",
                                        marginRight: "8px"
                                    }}>2</Button>
                            <Button variant={"contained"}
                                    disabled={generatedQuestions.length < 2}
                                    onClick={() => {
                                        setSelectedGeneratedQuestion(2);
                                    }}
                                    style={{
                                        backgroundColor: "#F55050",
                                        minWidth: "48px",
                                        height: "48px",
                                        borderRadius: "24px",
                                        marginRight: "8px"
                                    }}>3</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box  style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px"
            }}>
                <Button variant={"contained"}
                        disabled={isGeneratingQuestions || generatedQuestions.length > 0 || lectureText.length === 0}
                        onClick={generateQuestionsClicked}
                        style={{
                            backgroundColor: "#F55050",
                            width: "200px"
                        }}>Generate Questions</Button>
            </Box>
        </Box>
    </Root>
    );
}
export default ExamGenTrialView;