import React, { useEffect, useState } from "react";
import {MenuItem, TextField, Unstable_Grid2 as Grid} from "@mui/material";
import {CustomGrid, LectureItem, LecturesBox, LecturesList, SelectedLectureBox} from "../LecturesPage";

const ExamBuilderGridFree = (props) => {
    const { questions, selectedQuestions, questionClicked, examText, setExamText } = props;

    return (<CustomGrid container spacing={1}>
        <Grid xs={12} md={3}>
            <LecturesBox>
                <LecturesList spacing={2}>
                    {questions.map((question) => (
                        <LectureItem selected={selectedQuestions.find((item) => (item.id === question.id))} onClick={() => questionClicked(question)}>
                            {question.question}
                        </LectureItem>
                    ))}
                </LecturesList>
            </LecturesBox>
        </Grid>
        <Grid xs={12} md={9}>
            <SelectedLectureBox>
                <TextField
                    fullWidth
                    id="fullWidth"
                    multiline
                    placeholder={"Questions will be displayed here"} value={examText}
                    style={{
                        backgroundColor: "#ffffff",
                        overflowY:'scroll',
                        height: "calc(100%)",
                        marginBottom: "8px",
                    }}
                    onChange={(event) => {
                        setExamText(event.target.value);
                    }}
                />
            </SelectedLectureBox>
        </Grid>
    </CustomGrid>);
};
export default ExamBuilderGridFree;