import React, { createContext, useEffect, useState, useContext } from "react";
import {useApi} from "./ApiProvider";

export const AuthContext = createContext({
    currentUser: undefined,
    loginUser: () => {},
    registerUser: () => {},
    refreshUser: () => {},
    logout: () => {}
});
export const AuthProvider = ({ children}) => {
    const [authData, setAuthData] = useState(undefined);
    const [currentUser, setCurrentUser] = useState({});
    const { login, register, me } = useApi();

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        if(!accessToken) { return }

        const loadCurrentUser = async() => {
            await getMe();
        }
        loadCurrentUser();
    }, []);
    useEffect(() => {
        if(!authData) { return; }

        const loadCurrentUser = async() => {
           await getMe();
        }
        loadCurrentUser();
    }, [authData]);
    const getMe = async() => {
       const res = await me();

       setCurrentUser(res);
    };
    const loginUser = async(emailAddress, password) => {
        try {
            const res = await login(emailAddress, password);

            setAuthData(res);
        } catch(err) {
            console.log(err);

            throw err;
        }
    };
    const registerUser = async(firstName, lastName, emailAddress, password, institution) => {
        try {
            const res = await register(firstName, lastName, emailAddress, password, institution);

            setAuthData(res);
        } catch(err) {
            console.log(err);

            throw err;
        }
    };
    const refreshUser = async() => {
        try {
            await getMe();
        } catch(err) {
            console.log(err);

            throw err;
        }
    }
    const logout = async() => {
        try {
            setCurrentUser(undefined);
            setAuthData(undefined);
            localStorage.clear();
        }  catch(err) {
            console.log(err);
        }
    }
    return (
        <AuthContext.Provider
            value={{
                currentUser,
                loginUser,
                registerUser,
                refreshUser,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
};

