import React from "react";
import PropTypes from "prop-types";
import ErrorBlock from "../common/ErrorBlock"
import {Box, Button, TextField, Typography} from "@mui/material";
import FormField from "../common/forms/FormField";
import SpinnerButton from "../common/SpinnerButton";

const RegisterForm = (props) => {
  const { user, onChange, onRegister, registerState, saving } = props;

  return (
    <Box style={{
        background: "#fff",
        padding: "20px 20px 0px 20px",
        borderTop: "0px",
        color: "#666",
        textAlign: "left",
        width: "400px"
    }}>
      <form>
          <FormField
              xs={12}
              formElement={
                  <TextField
                      name="first_name"
                      label="First Name"
                      value={user.first_name}
                      onChange={onChange}
                      placeholder="Enter Your First Name"
                      error={registerState.inValidFields.firstName ? registerState.validationMessages.firstName : undefined}
                      helperText={registerState.inValidFields.firstName ? registerState.validationMessages.firstName : undefined}
                      fullWidth
                  />
              }
          />
        <FormField
          xs={12}
          formElement={
            <TextField
              name="last_name"
              label="Last Name"
              value={user.last_name}
              onChange={onChange}
              placeholder="Enter Your Last Name"
              error={registerState.inValidFields.lastName ? registerState.validationMessages.lastName : undefined}
              helperText={registerState.inValidFields.lastName ? registerState.validationMessages.lastName : undefined}
              fullWidth
            />
          }
        />
          <FormField
              xs={12}
              formElement={
                  <TextField
                      name="email_address"
                      label="Email Address"
                      value={user.email_address}
                      onChange={onChange}
                      placeholder="Enter Your Email Address"
                      error={registerState.inValidFields.emailAddress ? registerState.validationMessages.emailAddress : undefined}
                      helperText={registerState.inValidFields.emailAddress ? registerState.validationMessages.emailAddress : undefined}
                      fullWidth
                  />
              }
          />
        <FormField
          xs={12}
          formElement={
            <TextField
              type="password"
              name="password"
              label="Password"
              value={user.password}
              onChange={onChange}
              placeholder="Password"
              error={registerState.inValidFields.password ? registerState.validationMessages.password : undefined}
              helperText={registerState.inValidFields.password ? registerState.validationMessages.password : undefined}
              fullWidth
            />
          }
        />
          <FormField
              xs={12}
              formElement={
                  <TextField
                      name="institution"
                      label="Your College/University Name"
                      value={user.institution}
                      onChange={onChange}
                      placeholder="Enter College/University Name"
                      error={registerState.inValidFields.institution ? registerState.validationMessages.institution : undefined}
                      helperText={registerState.inValidFields.institution ? registerState.validationMessages.institution : undefined}
                      fullWidth
                  />
              }
          />
        <SpinnerButton
            loading={saving}
          type="submit"
          variant={"contained"}
          disabled={saving}
          value={saving ? "Creating account..." : "Create an Account"}
          onClick={onRegister}
          width={"240px"}
          spinnerColor={"#ffffff"}
          spinnerSize={18}
          style={{
              paddingLeft: "32px",
              paddingRight: "32px",
              marginTop: "24px",
              marginBottom: "24px",
          }}
        >
          Create an Account
        </SpinnerButton>
      </form>
    </Box>
  );
};
RegisterForm.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRegister: PropTypes.func.isRequired,
  registerState: PropTypes.object.isRequired,
  saving: PropTypes.bool,
};

export default RegisterForm;
