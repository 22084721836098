import React from "react";
import {Box} from "@mui/material";
import Pricing from "./component/Pricing";
import Logo from "../../images/logo.png";
import {
    useLoaderData,
} from "react-router-dom";

const UpgradePage = () => {
    const currentUser = useLoaderData();

    return (
        <Box style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <a href={"/lectures"}>
                <img src={Logo} style={{
                    height: "112px",
                    marginTop: "24px"
                }} />
            </a>
            <Pricing currentPlan={currentUser.account_type}  />
        </Box>);
}
export default UpgradePage;