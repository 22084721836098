import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/TextInput';
import {Link} from 'react-router-dom';
import {Box, TextField, Typography} from "@mui/material";
import FormField from "../common/forms/FormField";
import SpinnerButton from "../common/SpinnerButton";

const ForgotPasswordForm = ({email_address, onChange, onSubmit, saving, formState}) => {
  return (
      <Box style={{
        background: "#fff",
        padding: "20px 20px 0px 20px",
        borderTop: "0px",
        color: "#666",
        textAlign: "left",
        width: "400px"
      }}>
      <form>
        <FormField
            xs={12}
            formElement={
              <TextField
                  name="email_address"
                  label="Email Address"
                  value={email_address}
                  onChange={onChange}
                  placeholder="Enter Email Address"
                  error={formState.inValidFields.emailAddress ? formState.validationMessages.emailAddress : undefined}
                  helperText={formState.inValidFields.emailAddress ? formState.validationMessages.emailAddress : undefined}
                  fullWidth
              />
            }
        />
        <SpinnerButton
            loading={saving}
            variant={"contained"}
            disabled={saving}
            value={saving ? "Logging in..." : "Sign In"}
            onClick={onSubmit}
            width={"240px"}
            spinnerColor={"#ffffff"}
            spinnerSize={18}
            style={{
              paddingLeft: "32px",
              paddingRight: "32px",
              marginBottom: "24px",
            }}
        >
          {saving ? 'Submitting...' : 'Request Reset Link'}
        </SpinnerButton>
      </form>
    </Box>
  );
};
ForgotPasswordForm.propTypes = {
  email_address: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  errors: PropTypes.object
};

export default ForgotPasswordForm;
