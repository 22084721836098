import { redirect } from "react-router-dom";
import {useApi} from "./contexts/ApiProvider";

const ProtectedLoader = async (isLoggedIn) => {
    const { me } = useApi()
    try {
        if(!isLoggedIn) {
            return redirect("/login");
        }
        const user = await me();
        if(!user) {
            return redirect("/login");
        }
        return user;
    } catch(err) {
        console.log(err);
    }
};
export default ProtectedLoader;