import React from "react";

import { Box, Grid, Typography } from "@mui/material";

const FormField = (props) => {
  const { formElement, helpText, marginBottom = 24 } = props;


  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={"left"}
      style={{
        marginTop: "24px",
        marginBottom: marginBottom,
      }}
    >
      <Box style={{
        width: "100%"
      }}>
        {formElement}
      </Box>
      {helpText && (
        <Grid item xs={4}>
          <Typography variant={"body1"}>
            {helpText}
          </Typography>
        </Grid>
      )}
    </Box>
  );
};
FormField.defaultProps = {
  helpText: undefined,
  xs: 6,
};
export default FormField;
