import React, {createContext, useContext, useEffect, useState} from "react";
import {Alert, Snackbar} from "@mui/material";

export const FlashMessageContent = createContext({
    showErrorMessage: () => {},
    showWarningMessage: () => {},
    showInformationMessage: () => {},
    showSuccessMessage: () => {}
});
export const FlashMessageProvider = ({ children}) => {
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("info");
    const [message, setMessage] = useState("");

    const showErrorMessage = (message) => {
        setMessage(message);
        setSeverity("error");
        setOpen(true);
    }
    const showWarningMessage = (message) => {
        setMessage(message);
        setSeverity("warning");
        setOpen(true);
    }
    const showInformationMessage = (message) => {
        setMessage(message);
        setSeverity("info");
        setOpen(true);
    }
    const showSuccessMessage = (message) => {
        setMessage(message);
        setSeverity("success");
        setOpen(true);
    }
    const handleClose = (event, reason) => {
        if(reason === "clickaway") {
            return;
        }

        setOpen(false);
    }
    return (
        <FlashMessageContent.Provider
            value={{
                showSuccessMessage,
                showInformationMessage,
                showWarningMessage,
                showErrorMessage,
            }}
        >
            <Snackbar anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}  open={open} autoHideDuration={6000} onClose={handleClose} style={{
                width: "100%"
            }}>
                <Alert onClose={handleClose} severity={severity} xs={{ width: '1000px' }} >{message}</Alert>
            </Snackbar>
            {children}
        </FlashMessageContent.Provider>
    );
};
export const useFlashMessage = () => {
    const context = useContext(FlashMessageContent);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
};

