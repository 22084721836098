import React from "react";
import {Box, Button, Unstable_Grid2 as Grid, Link, Typography} from "@mui/material";
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from "../../../images/logo.png";
import {styled} from "@mui/material/styles";

const GridItem = styled(Grid)(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
}));
const SocialMediaLink = styled(Link)(({theme}) => ({
    marginRight: "24px",
    color: "#FFFFFF"
}));
const Footer = () => {
    return (<Box style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        minHeight: "140px",
    }}>
        <Grid container spacing={0} style={{
            width: "100%"
        }}>
            <GridItem item xs={12} md={3}>
                <img src={Logo} style={{
                    height: "56px",
                    marginRight: "24px"
                }} />
                <Typography variant={"body1"}>MyProfessorAI</Typography>
            </GridItem>
            <GridItem item xs={12} md={6}>
                <Typography variant={"body2"}>© 2023 All Rights Reserved</Typography>
                <Typography variant={"body2"} style={{
                    margin: "0 4px"
                }}>|</Typography>
                <Link href={"privacy.html"} color={"#FFFFFF"} style={{
                    fontSize: "14px"
                }}>Privacy Policy</Link>
                <Typography variant={"body2"} style={{
                    margin: "0 4px"
                }}>|</Typography>
                <Link href={"terms.html"} color={"#FFFFFF"} style={{
                    fontSize: "14px"
                }}>Terms of Service</Link>
            </GridItem>
            <GridItem item xs={12} md={3}>
                <Box style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start"
                }}>
                    <Typography variant={"body2"} style={{
                        marginBottom: "8px"
                    }}>Connect With Us:</Typography>
                    <Box>
                        <SocialMediaLink href={"https://www.twitter.com/MyProfessorAI"}>
                            <TwitterIcon sx={{ fontSize: 48 }} />
                        </SocialMediaLink>
                        <SocialMediaLink href={"https://www.linkedin.com/company/myprofessorai"}>
                            <LinkedInIcon sx={{ fontSize: 48 }} />
                        </SocialMediaLink>
                        <SocialMediaLink href={"https://www.instagram.com/myprofessorai/"}>
                            <InstagramIcon sx={{ fontSize: 48 }} />
                        </SocialMediaLink>
                    </Box>

                </Box>
            </GridItem>
        </Grid>
    </Box>);
}
export default Footer;