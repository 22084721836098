import React from "react";
import {Box, Button, Link, List, ListItem, ListItemIcon, ListItemText, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import {styled} from "@mui/material/styles";

import CheckCircle from '@mui/icons-material/CheckCircle';
import {useApi} from "../../contexts/ApiProvider";
import getStripe from "../../lib/getStripe";
import {useAuth} from "../../contexts/AuthProvider";

const CardWrapperLeft = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "rgba(207, 207, 207, 0.5)",
    [theme.breakpoints.down('md')]: {
        marginLeft: "20px",
        marginRight: "20px",
    },
    [theme.breakpoints.up('md')]: {
        alignItems: "center",
    },
}));
const CardWrapperRight = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: "40px",
    [theme.breakpoints.down('md')]: {
        marginLeft: "20px",
        marginRight: "20px",
    },
    [theme.breakpoints.up('md')]: {
        alignItems: "center",
    },
}));
const Card = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "60px",
    [theme.breakpoints.down('md')]: {
        marginLeft: "20px",
        marginRight: "20px",
    },
    [theme.breakpoints.up('md')]: {
        width: "350px",
    },
}));
const PlanTitleTypography = styled(Typography)(({theme}) => ({
    '&': {
        fontSize: "32px",
        marginBottom: "8px"
    }
}));
const PlanDescriptionTypography = styled(Typography)(({theme}) => ({
    '&': {
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "24px",
        lineHeight: "32px",
        marginBottom: "24px"
    }
}));
const PlanPriceTypography = styled(Typography)(({theme}) => ({
    '&': {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "40px",
        lineHeight: "48px",
    }
}));
const PlanPriceFrequencyTypography = styled(Typography)(({theme}) => ({
    '&': {
        fontStyle: "normal",
        fontWeight: "100",
        fontSize: "18px",
        lineHeight: "18px",
        textTransform: "lowercase"
    }
}));
const FeatureListHeaderTypography = styled(Typography)(({theme}) => ({
    '&': {
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        marginBottom: "4px"
    }
}));
const FeatureListItemText = styled(ListItemText)(({theme}) => ({
    '& .MuiListItemText-primary': {
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center"
    }
}));
const FeatureListItem = (props) => {
    const { featureText } = props;
    return (<ListItem>
        <ListItemIcon style={{
            alignItems: "center",
            justifyContent: "flex-start",
            minWidth: "auto",
            marginRight: "8px",
        }}>
            <CheckCircle style={{
                backgroundColor: "transparent"
            }} />
        </ListItemIcon>
        <FeatureListItemText
            primary={featureText} />
    </ListItem>);
}
const Root = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "40px",
    paddingBottom: "40px",
    width: "100%",
}));
const TitleWrapper = styled(Box)(({theme}) => ({
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: "80px",
        paddingRight: "80px",
    },
}));
const PillButton = styled(Button)(({theme}) => ({
    backgroundColor: "#F55050",
    height: "70px",
    maxWidth: "350px",
    borderRadius: "35px",
}));
const getButtonTitle = (usersPlan, plan) => {
    if(!usersPlan) {
        if(plan === "Free") {
            return "Get Started";
        }
        return "Subscribe";
    }
    if(usersPlan === plan) {
        return "Your Plan"
    }
    return "Upgrade";
}
const Pricing = (props) => {
    const { currentPlan } = props;

    const { currentUser } = useAuth();
    const { createOrder } = useApi();

    const startCheckout = async(planName) => {
        try {
            const result = await createOrder(planName);
            await handleCheckout(result);
        } catch(err) {
            console.log(err);
        }
    }

    const handleCheckout = async(order) => {
        const stripe = await getStripe();
        const { error } = await stripe.redirectToCheckout({
            lineItems: [
                {
                    price: process.env.REACT_APP_BASIC_PLAN_PRICE_ID,
                    quantity: 1,
                },
            ],
            mode: 'subscription',
            successUrl: `${process.env.REACT_APP_REDIRECT_BASE_URL}/orders/${order._id}/complete`,
            cancelUrl: `${process.env.REACT_APP_REDIRECT_BASE_URL}/orders/${order._id}/cancel`,
            customerEmail: currentUser.email_address,
        });
        if(error) {
            console.warn(error.message);
        }
    }
    return (<Root>
        <TitleWrapper>
            <Typography variant={"h3"} style={{
                marginBottom: "40px"
            }}>Pricing</Typography>
            <Typography variant={"h6"}>Straight forward pricing. Choose from 2 plans.</Typography>
        </TitleWrapper>
        <Grid container spacing={0} style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px"
        }}>
            <Grid xs={12} md={6}>
                <CardWrapperLeft>
                    <Card>
                        <PlanTitleTypography variant={"body1"}>Free Plan</PlanTitleTypography>
                        <PlanDescriptionTypography variant={"body1"}>
                            Create short quizzes and tests of up to 10 questions from one of yours lectures.
                        </PlanDescriptionTypography>
                        <Box style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginBottom: "28px"
                        }}>
                            <PlanPriceTypography>$0</PlanPriceTypography>
                        </Box>
                        <Box style={{
                            marginBottom: "28px"
                        }}>
                            <PillButton href={"/register"} disabled={currentPlan === "Free"} component={Link} fullWidth variant={"contained"}>{getButtonTitle(currentPlan, "Free")}</PillButton>
                        </Box>
                        <FeatureListHeaderTypography variant={"body1"}>This includes:</FeatureListHeaderTypography>
                        <List dense={true}>
                            <FeatureListItem featureText="up to 10 ChatGPT generated questions per day" />
                            <FeatureListItem featureText="limited to one lecture source" />
                            <FeatureListItem featureText="5000 word limit per lecture" />
                            <FeatureListItem featureText="generated questions not saved" />
                        </List>
                    </Card>
                </CardWrapperLeft>
            </Grid>
            <Grid xs={12} md={6}>
                <CardWrapperRight>
                    <Card>
                        <PlanTitleTypography variant={"body1"}>Basic Plan</PlanTitleTypography>
                        <PlanDescriptionTypography variant={"body1"}>
                            Create an unlimited number of AI generated questions from any number of your lectures.
                        </PlanDescriptionTypography>
                        <Box style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginBottom: "28px"
                        }}>
                            <PlanPriceTypography>$12.99</PlanPriceTypography>
                            <Box style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                marginLeft: "4px"
                            }}>
                                <PlanPriceFrequencyTypography>Per</PlanPriceFrequencyTypography>
                                <PlanPriceFrequencyTypography>Month</PlanPriceFrequencyTypography>
                            </Box>
                        </Box>
                        <Box style={{
                            marginBottom: "28px"
                        }}>
                            {currentPlan !== "Free" && <PillButton href={"/register/basic"} disabled={currentPlan === "Paid"} component={Link} fullWidth variant={"contained"}>{getButtonTitle(currentPlan, "Paid")}</PillButton>}
                            {currentPlan === "Free" && <PillButton onClick={() => startCheckout("Basic Plan")} disabled={currentPlan === "Paid"} component={Link} fullWidth variant={"contained"}>{getButtonTitle(currentPlan, "Paid")}</PillButton>}
                        </Box>
                        <FeatureListHeaderTypography variant={"body1"}>This includes:</FeatureListHeaderTypography>
                        <List dense={true}>
                            <FeatureListItem featureText="unlimited ChatGPT generated questions" />
                            <FeatureListItem featureText="unlimited number of lectures" />
                            <FeatureListItem featureText="unlimited lecture lectures" />
                            <FeatureListItem featureText="securely save lectures, questions and exams" />
                        </List>
                    </Card>
                </CardWrapperRight>
            </Grid>
        </Grid>
    </Root>);
}
export default Pricing;