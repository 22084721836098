import React from 'react';
import PropTypes from 'prop-types'
import SecureTextInput from '../common/SecureTextInput';
import {Link} from 'react-router-dom';
import {Box, TextField} from "@mui/material";
import FormField from "../common/forms/FormField";
import SpinnerButton from "../common/SpinnerButton";

const ResetPasswordForm = ({password, password_confirm, onChangePassword, onChangePasswordConfirm, onSubmit, saving, formState}) => {
  return (
      <Box style={{
        background: "#fff",
        padding: "20px 20px 0px 20px",
        borderTop: "0px",
        color: "#666",
        textAlign: "left",
        width: "400px"
      }}>
      <form>
        <FormField
            xs={12}
            formElement={
              <TextField
                  type={"password"}
                  name="password"
                  label="Password"
                  value={password}
                  onChange={onChangePassword}
                  placeholder="Enter a New Password"
                  error={formState.inValidFields.password ? formState.validationMessages.password : undefined}
                  helperText={formState.inValidFields.password ? formState.validationMessages.password : undefined}
                  fullWidth
              />
            }
        />
        <FormField
            xs={12}
            formElement={
              <TextField
                  type={"password"}
                  name="password_confirm"
                  label="Confirm Password"
                  value={password_confirm}
                  onChange={onChangePasswordConfirm}
                  placeholder="Enter a New Password again"
                  error={formState.inValidFields.confirmPassword ? formState.validationMessages.confirmPassword : undefined}
                  helperText={formState.inValidFields.confirmPassword ? formState.validationMessages.confirmPassword : undefined}
                  fullWidth
              />
            }
        />
        <SpinnerButton
            loading={saving}
            variant={"contained"}
            disabled={saving}
            value={saving ? "Submitting..." : "Set New Password"}
            onClick={onSubmit}
            width={"240px"}
            spinnerColor={"#ffffff"}
            spinnerSize={18}
            style={{
              paddingLeft: "32px",
              paddingRight: "32px",
              marginBottom: "24px",
            }}
        >
          {saving ? 'Submitting...' : 'Set New Password'}
        </SpinnerButton>
      </form>
    </Box>
  );
};
ResetPasswordForm.propTypes = {
  password: PropTypes.string.isRequired,
  password_confirm: PropTypes.string.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onChangePasswordConfirm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  errors: PropTypes.object
};

export default ResetPasswordForm;
