import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import RegisterForm from "./RegisterForm";
import { Link, Typography } from "@mui/material";
import AuthLayout from "./AuthLayout";
import AuthBox from "./AuthBox";
import {useAuth} from "../contexts/AuthProvider";
import {useNavigate, useParams} from "react-router-dom";
import ReactGA from "react-ga4";
import getStripe from "../lib/getStripe";
import {useApi} from "../contexts/ApiProvider";
import handleError from "../common/ErrorHandler";
import {useFlashMessage} from "../contexts/FlashMessageProvider";

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const RegisterPage = (props) => {
  const { plan } = useParams();

  const navigate = useNavigate();
  const { registerUser } = useAuth();
  const [saving, setSaving] = useState(false);
  const {createOrder} = useApi();
  const { showErrorMessage } = useFlashMessage();

  const [registerState, setRegisterState] = useState({
    isInValid: false,
    inValidFields: {
      firstName: false,
      lastName: false,
      emailAddress: false,
      password: false,
      institution: false
    },
    validationMessages: {
      firstName: "First Name is required",
      lastName: "Last Name is required",
      emailAddress: "Please enter a valid email address",
      password: "Password is required",
      institution: "College/University name is required"
    },
    validationMessage: "Please correct the issues below and submit again.",
    isError: false,
    isEnabled: true,
    isSent: false,
    errorMessage: "An error occurred"
  });
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    password: "",
    institution: ""
  });

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/register" });
  }, []);

  const handleCheckout = async(order) => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: process.env.REACT_APP_BASIC_PLAN_PRICE_ID,
          quantity: 1,
        },
      ],
      mode: 'subscription',
      successUrl: `${process.env.REACT_APP_REDIRECT_BASE_URL}/orders/${order._id}/complete`,
      cancelUrl: `${process.env.REACT_APP_REDIRECT_BASE_URL}/orders/${order._id}/cancel`,
      customerEmail: user.email_address,
    });
    if(error) {
      console.warn(error.message);
    }
  }
  const updateUserState = (event) => {
    event.preventDefault();

    const updatedUser = Object.assign({}, user);

    const field = event.target.name;
    updatedUser[field] = event.target.value;

    return setUser(updatedUser);
  };
  const registerClicked = async(event) => {
    try {
      event.preventDefault();

      let isFirstNameValid = true;
      let isLastNameValid = true
      let isEmailValid = true;
      let isPasswordValid = true;
      let isInstitutionValid = true;
      if(user.first_name.length === 0) {
        isFirstNameValid = false;
      }
      if(user.last_name.length === 0) {
        isLastNameValid = false;
      }
      if(!validateEmail(user.email_address)) {
        isEmailValid = false;
      }
      if(user.password.length === 0) {
        isPasswordValid = false;
      }
      if(user.institution.length === 0) {
        isInstitutionValid = false;
      }
      const isValid = isFirstNameValid && isLastNameValid && isEmailValid && isPasswordValid && isInstitutionValid;

      if(!isValid) {
        setRegisterState(prev => Object.assign({}, prev, {
          isInValid: true,
          inValidFields: {
            firstName: !isFirstNameValid,
            lastName: !isLastNameValid,
            emailAddress: !isEmailValid,
            password: !isPasswordValid,
            institution: !isInstitutionValid
          },
          isSent: false,
          isError: false,
          isSuccess: false,
          errorMessage: ""
        }));

        return;
      }
      setSaving(true);
      ReactGA.event({
        category: "Auth",
        action: "Register Clicked",
      });
      await registerUser(user.first_name, user.last_name, user.email_address, user.password, user.institution);

      const startCheckout = async(planName) => {
        try {
          const result = await createOrder(planName);
          await handleCheckout(result);
        } catch(err) {
          console.log(err);
        }
      }
      if(plan === "basic") {
        startCheckout("Basic Plan");
      } else {
        navigate("/lectures");
      }

      setRegisterState(prev => Object.assign({}, prev, {
        isInValid: false,
        inValidFields: {
          firstName: false,
          lastName: false,
          emailAddress: false,
          password: false,
          institution: false
        },
        isSent: true,
        isError: false,
        isSuccess: true,
        errorMessage: ""
      }));
    } catch(err) {
      const message = handleError(err);

      showErrorMessage(message);

      setRegisterState(prev => Object.assign({}, prev, {
        isInValid: false,
        inValidFields: {
          firstName: false,
          lastName: false,
          emailAddress: false,
          password: false,
          institution: false
        },
        isSent: false,
        isError: true,
        isSuccess: false,
        errorMessage: message
      }));
    } finally {
      setSaving(false);
    }

  };
  return (
    <AuthLayout heading={"Get Started With MyProfessorAI"}>
      <AuthBox>
        <RegisterForm
          user={user}
          onChange={updateUserState}
          onRegister={registerClicked}
          registerState={registerState}
          saving={saving}
        />
      </AuthBox>
      <Typography variant={"body1"} component={"h2"}>
        {"Already have an account? "}
        <Link href="/login">Login</Link>
      </Typography>
    </AuthLayout>
  );
};
RegisterPage.propTypes = {
  user: PropTypes.object,
  currentUser: PropTypes.object,
  actions: PropTypes.object.isRequired,
};
export default RegisterPage;
