import React from "react";
import {AppBar, Box, Button, Drawer, Divider, List, ListItem, ListItemButton, ListItemText, IconButton, Menu, Link, Toolbar, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;
const navItems = [{
    title: 'About',
    target: "#"
}, {
    title: 'Our Tools',
    target: "#tools"
}, {
    title: 'Pricing',
    target: "#pricing"
},{
    title: 'FAQs',
    target: "#faqs"
}, {
    title: 'Contact Us',
    target: "#contactus"
}];

const CustomAppBar = styled(AppBar)(({theme}) => ({
    backgroundColor: "#FFFFFF",
    color: "#000000",
}));
const CustomLink = styled(Link)(({theme}) => ({
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "100",
    fontSize: "14px",
    lineHeight: "18px",
    marginRight: "54px",
    color: "#000000",
    textTransform: "uppercase"
}));

const Header = (props) => {
    const { window, hideMenu } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                MyProfessorAI
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.title} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <CustomLink href={item.target} underline={"none"}>{item.title}</CustomLink>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            minHeight: "80px",
            paddingLeft: "0px",
            paddingRight: "0px",
        }}>
            <CustomAppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        MyProfessorAI
                    </Typography>
                    {!hideMenu && <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <CustomLink href={item.target} key={item.title} underline={"none"}>
                                {item.title}
                            </CustomLink>
                        ))}
                        <Button variant={"contained"} component={Link} href={"/login"}>Login</Button>
                    </Box>}
                </Toolbar>
            </CustomAppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>);
}
export default Header;