import * as React from "react";
import * as ReactDOM from "react-dom";
import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import LoginPage from "./components/auth/LoginPage";
import RegisterPage from "./components/auth/RegisterPage";
import HomePage from "./components/home/HomePage";
import {AuthProvider, useAuth} from "./components/contexts/AuthProvider";
import {ApiProvider, useApi} from "./components/contexts/ApiProvider";
import {PubNubProvider} from "./components/contexts/PubNubProvider";
import LecturesPage from "./components/lectures/LecturesPage";
import Header from "./components/Header";
import GenerateExamPage from "./components/examGen/GenerateExamPage";
import QuestionBankPage from "./components/questionBank/QuestionBankPage";

import ReactGA from "react-ga4";

import {createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material";
import OrderComplete from "./components/auth/OrderComplete";
import OrderCancelled from "./components/auth/OrderCancelled";
import PricingPage from "./components/home/PricingPage";
import UpgradePage from "./components/home/UpgradePage";
import ForgotPasswordPage from "./components/auth/ForgotPasswordPage";
import ResetPasswordPage from "./components/auth/ResetPasswordPage";
import ProtectedLoader from "./components/ProtectedLoader";
import {useState} from "react";
import {FlashMessageProvider} from "./components/contexts/FlashMessageProvider";
import OAuthRedirectLoader from "./components/OAuthRedirectLoader";


let theme = createTheme({
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                containedPrimary: {
                    backgroundColor: "#F55050",
                    '&:hover': {
                        backgroundColor: "#FC6A6A"
                    }
                },
            },
            defaultProps: {
                // The props to change the default for.
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
    },
    typography: {
        fontFamily: [
            'Inter',
            'serif',
        ].join(','),
        h2: {
            fontStyle: "normal",
            fontWeight: "100",
            fontSize: "64px",
        },
        h3: {
            fontStyle: "normal",
            fontWeight: "100",
            fontSize: "56px",
        },
        h4: {
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "48px",
        },
        h5: {
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "36px",
        },
        h6: {
            fontStyle: "normal",
            fontWeight: "100",
            fontSize: "24px",
        },
        body1: {
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "18px",
        },
        error: {
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            color: "#FF0000",
            marginTop: "4px",
        },
        success: {
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "18px",
            color: "#408140",
        }
    },
});
theme = responsiveFontSizes(theme);

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const App = () => {
    const { completeOrder } = useApi();
    const { refreshUser } = useAuth();
    const accessToken = localStorage.getItem('access_token');

    const isLoggedIn = accessToken;

    const router = createBrowserRouter([
        {
            path: "/",
            element: <HomePage />,
        },
        {
            path: "/pricing",
            element: <PricingPage />,
        },
        {
            path: "/upgrade",
            element: <UpgradePage />,
            loader: () => ProtectedLoader(isLoggedIn)
        },
        {
            path: "/login",
            element: <LoginPage />,
        },
        {
            path: "/forgot_password",
            element: <ForgotPasswordPage />,
        },
        {
            path: "/reset_password/:id",
            element: <ResetPasswordPage />,
        },
        {
            path: "/register/:plan?",
            element: <RegisterPage />,
        },
        {
            path: "/oauth_redirect",
            element: <Navigate to="/lectures" />,
            loader: async() => await OAuthRedirectLoader()
        },
        {
            path: "/lectures",
            element: <LecturesPage />,
            loader: async() => await ProtectedLoader(isLoggedIn)
        },
        {
            path: "/exams",
            element: <GenerateExamPage />,
            loader: async() => await ProtectedLoader(isLoggedIn)
        },
        {
            path: "/questionBank",
            element: <QuestionBankPage />,
            loader: () => ProtectedLoader(isLoggedIn)
        },
        {
            path: "/orders/:id/complete",
            element: <OrderComplete />,
        },
        {
            path: "/orders/:id/cancel",
            element: <OrderCancelled />,
        }
    ]);

    return (<>
        <RouterProvider router={router} />
    </>);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <FlashMessageProvider>
            <PubNubProvider>
                <ApiProvider>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </ApiProvider>
            </PubNubProvider>
        </FlashMessageProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
