import React from "react";
import PropTypes from "prop-types";
import Logo from "../../images/logo.png";

import { Box, Typography } from "@mui/material";
import MyProfessorAILabel from "../home/component/MyProfessorAILabel";
import {styled} from "@mui/material/styles";

const Root = styled(Box)(({ theme}) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: "100vh",
    textAlign: "center",
}));
const AuthLayout = (props) => {
  const { children } = props;

  return (
    <Root>
        <a href={"/"}>
          <img src={Logo} style={{
              height: "112px",
              marginTop: "24px"
          }} />
        </a>
      <Typography variant={"h6"} style={{
        marginTop: "24px",
        marginBottom: "12px"
      }}>
        {props.heading}
      </Typography>
      {children}
    </Root>
  );
};
AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  heading: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};
AuthLayout.defaultProps = {
  children: {},
  heading: "",
  classes: {},
};

export default AuthLayout;
