import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {Box, Button, Popover, TextField, Typography} from "@mui/material";

const QuestionDetailsPopover = (props) => {
    const { anchorEl, setAnchorEl, selectedQuestion, setSelectedQuestion, saveQuestionClicked } = props;

    const open = Boolean(anchorEl);
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (<Popover
        id="question-details"
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open={open}
        onClose={handlePopoverClose}
        disable
        style={{
            width: "600px"
        }}
    >
        {selectedQuestion && <Box style={{
            padding: 16,
            minWidth: "460px",
            height: "600px"
        }}>
            <Typography variant={"body1"}>Question</Typography>
            <TextField multiline fullWidth={true} value={selectedQuestion?.question} style={{
                marginBottom: "8px",
                background: "rgba(217, 217, 217, 0.25)",
                border: "1px solid #D9D9D9"
            }}
                       onChange={(event) => {
                           setSelectedQuestion(prev => Object.assign({}, prev, { question: event.target.value }));
                       }}
            />
            {selectedQuestion.possible_answers && <>
                <Typography variant={"body1"}>Answer Choices</Typography>
                {selectedQuestion.possible_answers.map((answer, index) => {
                    return (
                        <TextField fullWidth={true} value={answer} style={{
                            marginBottom: "8px",
                            background: "rgba(217, 217, 217, 0.25)",
                            border: "1px solid #D9D9D9"
                        }}
                                   onChange={(event) => {
                                       const updatedQuestion = Object.assign({}, selectedQuestion);
                                       updatedQuestion.possible_answers[index] = event.target.value;

                                       setSelectedQuestion(updatedQuestion);
                                   }} />
                    );
                })}
            </>}
            <Button variant={"contained"} onClick={() => saveQuestionClicked(selectedQuestion)}>Save Changes</Button>
        </Box>}
    </Popover>);
}
export default QuestionDetailsPopover;