import React from "react";
import ReactGA from "react-ga4";
import {useNavigate} from "react-router-dom";

const handleError = (err) => {
    let message = "Sorry, an error occurred.  Please try again."

    if(err.response?.data) {
        message = err.response.data.message;

        ReactGA.event({
            category: "Error",
            action: `Error: ${err.response.data.majorCode}`,
        });
    }

    return message;
}
export default handleError;