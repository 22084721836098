import React from "react";
import {Box, Link, Typography} from "@mui/material";
import Pricing from "./component/Pricing";
import Header from "./component/Header";
import Footer from "./component/Footer";
import {useAuth} from "../contexts/AuthProvider";
import Logo from "../../images/logo.png";

const PricingPage = () => {
    const { currentUser } = useAuth();

    return (
        <Box style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 0px"
        }}>
            <a href={"/"}>
                <img src={Logo} style={{
                    height: "112px",
                    marginTop: "24px"
                }} />
            </a>
            <Pricing />
            <Typography variant={"body1"} component={"h2"}>
                {"Already have an account? "}
                <Link href="/login">Login</Link>
            </Typography>
        </Box>);
}
export default PricingPage;