import React from "react";
import {Box, List, ListItem, ListItemText, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import {styled} from "@mui/material/styles";

const Root = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    paddingTop: "40px",
    paddingBottom: "40px",
    backgroundColor: "rgba(217, 217, 217, 0.25)",
}));
const TitleWrapper = styled(Box)(({theme}) => ({
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: "80px",
        paddingRight: "80px",
    },
}));

const FAQs = (props) => {
    return (<Root>
        <TitleWrapper>
            <Typography variant={"h3"} style={{
                marginBottom: "40px"
            }}>FAQs</Typography>
            <Typography variant={"h6"}>Here’s some questions folks are starting to ask us..</Typography>
        </TitleWrapper>
        <Grid container spacing={0} style={{
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Grid xs={12} md={6}>
                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>1. How does MyProfessorAI work?</Typography>
                            <Typography variant={"body2"} style={{
                                marginBottom: "12px"
                            }}>We take your transcribed lectures and make them accessible to AI technologies like ChatGPT.  Your lectures can be added to our app by simply copying and pasting the transcribed text from a source document.  In many cases, your LMS system has the ability to transcribe videos of your lectures to text and the text can be copied from your LMS.  Once the lectures are imported, we use OpenAI/ChatGPT to generate high quality questions specific to your lectures that can be used on an exam or other test material.</Typography>
                            <Typography variant={"body2"}>In the future, we'll even allow you or your students to ask questions about the lecture material and get real time natural language responses.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>2. Do I need to have my lectures transcribed?</Typography>
                            <Typography variant={"body2"}>Yes, your lecture must be in text document that you can be copy and paste into our app.  If you have video recordings of your lectures, check your LMS system documentation to see if transcribe from video is an available feature.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>3. Do you have a free plan?</Typography>
                            <Typography variant={"body2"}> Yes, we offer a free plan that allows you to create 10 questions from a single lecture. This is perfect for short tests and quizzes and is totally free. </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>4. Does the basic plan auto-renew?</Typography>
                            <Typography variant={"body2"}>Yes, the basic plan auto-renews every month and can be cancelled at anytime. This plan allows you to create an unlimited number of questions from any number of lectures, making it an ideal choice for professors who need to create comprehensive exams.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>5. Can I cancel my basic plan?</Typography>
                            <Typography variant={"body2"}>Yes, you can cancel your basic plan at anytime. We understand that you may not be sure if this will work with your lectures, so we offer a free account that allows you to generate 10 questions from a single lecture. If you want to try it out before committing to a paid plan, you can generate 3 questions from a provided lecture transcript right on our webpage under Try It Now. </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>6. How can I make sure this will work with my lecture material?</Typography>
                            <Typography variant={"body2"}>If you are not sure how well this will work with your lectures, you can try it out before committing to a paid plan. We offer a free account that allows you to generate 10 questions from a single lecture. Additionally, you can generate 3 questions from a provided lecture transcript right on our webpage under Try It Now. This will give you a better idea of how our system works and how it can help you create exams for your students.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>7. How are questions generated?</Typography>
                            <Typography variant={"body2"}>Questions are generated using OpenAI and ChatGPT by analyzing the lecture transcripts provided by the professor. This ensures that the questions are only from the lecture material and not from general knowledge, textbooks, or other sources found on the internet. </Typography>
                        </ListItemText></ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>8. What if I don’t like a question?</Typography>
                            <Typography variant={"body2"}>If you don't like a question, you can manually edit the question, ask ChatGPT to reword the question and possible answers, or remove the question from the exam. It's best to request more questions than are actually needed so a few can be removed. From time to time, ChatGPT may word a question incorrectly or even return an incorrect answer. For this reason, it is important that the professor reviews the generated questions before giving the exam.</Typography>
                        </ListItemText></ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>9.  What if the question is incorrect?</Typography>
                            <Typography variant={"body2"}>If the question is incorrect, it may be due to a misunderstanding in the lecture material or a typo in the lecture text. For this reason, it is important that the professor reviews the generated questions before giving the exam. Additionally, the app and quality of questions returned by ChatGPT improves as we collect more and more feedback on what are good and what are bad questions.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>10. Do you store all my lectures and other class material?</Typography>
                            <Typography variant={"body2"}>Yes, we store all lecture material and other material provided to generate questions securely on our servers for the basic plan. This way you can easily select which lecture material you want to build questions from. Additionally, all generated questions are stored as long as you have an account, allowing you to build a question bank of questions over time. </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>11. How long are exams and questions available?</Typography>
                            <Typography variant={"body2"}>Exams and questions created with our app are available for as long as you have an account. With the free plan, you can generate 10 questions from a single lecture, but the generated questions are not stored on our servers. With the basic plan, all lecture material and questions generated are securely stored on our servers. This allows you to create an unlimited number of questions from any number of lectures and store them so you can build a question bank over time.</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"body1"} style={{
                                marginBottom: "8px"
                            }}>12. Can I create multiple versions of the same exam/question set?</Typography>
                            <Typography variant={"body2"}>Yes, with the basic plan you can create multiple versions of the same exam/question set. The basic plan allows you to shuffle the questions and answers to create additional exams with the same questions. This is a great way to ensure that your students are not memorizing the answers to the same questions.</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    </Root>);
}
export default FAQs;