import React, { useEffect } from "react";
import PropTypes from "prop-types";

import {useNavigate, useParams} from "react-router-dom";
import {useApi} from "../contexts/ApiProvider";
import {useFlashMessage} from "../contexts/FlashMessageProvider";
import handleError from "../common/ErrorHandler";


const OrderCancelled = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { cancelOrder } = useApi();
    const { showSuccessMessage } = useFlashMessage();

    useEffect(() => {
        if(!id) { return; }

        const updateOrder = async(orderId) => {
            try {
                await cancelOrder(orderId);

                navigate("/lectures");

                showSuccessMessage("Your order was successfully cancelled.");
            } catch(err) {
                handleError(err);

                showSuccessMessage("Your order was successfully cancelled.");

                navigate("/lectures");
            }
        }

        updateOrder(id);
    }, [id]);

    return (<></>);
}
export default OrderCancelled;