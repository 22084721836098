import React, { useEffect, useState } from "react";
import ResetPasswordForm from './ResetPasswordForm';
import {Link, Typography} from "@mui/material";
import AuthLayout from "./AuthLayout";
import AuthBox from "./AuthBox";
import {useApi} from "../contexts/ApiProvider";
import {useNavigate, useParams} from "react-router-dom";
import {useFlashMessage} from "../contexts/FlashMessageProvider";
import handleError from "../common/ErrorHandler";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {verifyLink, resetPassword} = useApi();
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [saving, setSaving] = useState(false);
  const [isValidLink, setIsValidLink] = useState(false);
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();

    const [formState, setFormState] = useState({
        isInValid: false,
        inValidFields: {
            password: false,
            confirmPassword: false,
        },
        validationMessages: {
            password: "Password is required",
            confirmPassword: "Confirm Password is required"
        },
        validationMessage: "Please correct the issues below and submit again.",
        isError: false,
        isSuccess: false,
        isEnabled: true,
        errorMessage: "An error occurred"
    });

  useEffect(() => {
      //verify link
      const verify = async() => {
          try {
              const results = await verifyLink(id);

              setIsValidLink(results.isValid);
          } catch(err) {
              console.log(err);

              setIsValidLink(false);
          } finally {
              setLoading(false)
          }
      }
      verify();
  }, [id]);
  const onSubmit = async() => {
      try {
          if(password.length === 0) {
              setFormState(prev => Object.assign({}, prev, {
                  isInValid: true,
                  inValidFields: {
                      password: true,
                      confirmPassword: false
                  },
                  validationMessages: {
                      password: "Password is required",
                  },
                  isError: false,
                  isSuccess: true,
                  errorMessage: ""
              }));

              return;
          }
          if(password.length === 0) {
              setFormState(prev => Object.assign({}, prev, {
                  isInValid: true,
                  inValidFields: {
                      password: false,
                      confirmPassword: true
                  },
                  validationMessages: {
                      confirmPassword: "Confirm Password is required",
                  },
                  isError: false,
                  isSuccess: true,
                  errorMessage: ""
              }));

              return;
          }
          if(password !== confirmPassword) {
              setFormState(prev => Object.assign({}, prev, {
                  isInValid: true,
                  inValidFields: {
                      password: true,
                      confirmPassword: true
                  },
                  validationMessages: {
                      password: "Password and Confirm password must match",
                      confirmPassword: "Password and Confirm password must match"
                  },
                  isError: false,
                  isSuccess: true,
                  errorMessage: ""
              }));

              return;
          }
          setSaving(true);

          await resetPassword(id, password);

          navigate("/login");

          showSuccessMessage("Password was successfully updated. Please login.")
      } catch(err) {
          const message = handleError(err);

          showErrorMessage(message);

          setFormState(prev => Object.assign({}, prev, {
              isInValid: false,
              inValidFields: {
                  password: false,
                  confirmPassword: false
              },
              isError: true,
              isSuccess: false,
              errorMessage: "Sorry, an error occurred.  Please try again."
          }));
      } finally {
          setSaving(false);
      }
  }
  if(loading) {
      return (<></>);
  }
  return (
      <AuthLayout heading={"Reset Your Password"}>
          <AuthBox>
              {isValidLink && <ResetPasswordForm
              password={password}
              password_confirm={confirmPassword}
              onChangePassword={(e) => setPassword(e.target.value)}
              onChangePasswordConfirm={(e) => setConfirmPassword(e.target.value)}
              onSubmit={onSubmit}
              saving={saving}
              formState={formState}
          />}
              {!isValidLink && <Typography variant={"body1"} style={{
                  padding: "40px 80px"
              }}>
                  Sorry, the reset password link is expired or no longer valid.  Please request a new link.
              </Typography>}
          </AuthBox>
          <Typography variant={"body1"} component={"h2"}>
              <Link href="/login">Back to Sign In</Link>
          </Typography>
      </AuthLayout>
  );
}

export default ResetPasswordPage;
