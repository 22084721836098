import React, { createContext, useEffect, useState, useContext } from "react";
import PubNub from "pubnub";

export const PubNubContext = createContext({
    pubNub: undefined,
});
export const PubNubProvider = ({ children}) => {
    const [pubNub, setPubNub] = useState(undefined);

    useEffect(() => {
        const pubnub = new PubNub({
            publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
            subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
            userId: "1",
        });

        setPubNub(pubnub);
    }, []);

    return (
        <PubNubContext.Provider
            value={{
                pubNub
            }}
        >
            {children}
        </PubNubContext.Provider>
    );
};
export const usePubNub = () => {
    const context = useContext(PubNubContext);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
};
