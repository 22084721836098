import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import {styled} from "@mui/material/styles";

const SpinnerAdornment = styled(CircularProgress)(({theme}) => ({
    marginRight: 8
}));

const SpinnerButton = (props) => {
    const {
        children,
        loading,
        spinnerColor,
        spinnerSize = 30,
        ...rest
    } = props;
    return (
        <Button {...rest}>
            {loading && (
                <SpinnerAdornment
                    size={spinnerSize}
                    style={{
                        color: spinnerColor
                    }}
                />
            )}
            {children}
        </Button>
    )
};

export default SpinnerButton;