import React from "react";
import {Box, Button, TextField, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {CustomGrid, LectureItem, LecturesBox, LecturesList, SelectedLectureBox} from "../LecturesPage";
import SpinnerButton from "../../common/SpinnerButton";
import {styled} from "@mui/material/styles";

const GenerateQuestionsButton = styled(SpinnerButton)(({isGeneratingQuestions, theme}) => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: 70,
    backgroundColor: isGeneratingQuestions ? "#C0C0C0" : "#B0E0E6",
    color: isGeneratingQuestions ? "#666699" : "#333399",
    '&:hover': {
        backgroundColor: "#FFA500",
        color: "#FFFFFF"
    }
}));
const QuestionBankGridFree = (props) => {
    const { selectedLecture, setSelectedLecture, questions, selectedQuestion, editQuestionClicked, generateQuestionsClicked, isGeneratingQuestions } = props;
    return (<CustomGrid container spacing={1}>
        <Grid xs={12} md={10}>
            <SelectedLectureBox>
                {selectedLecture && <Box>
                    <TextField
                        fullWidth id="fullWidth"
                        multiline
                        autoFocus
                        placeholder={"Copy and Paste Lecture Content Here"}
                        value={selectedLecture.text} onChange={(event) => {
                        const updatedLecture = Object.assign({}, selectedLecture);
                        updatedLecture.text = event.target.value;

                        setSelectedLecture(updatedLecture);
                    }}
                        InputProps={{
                            style: {
                                fontSize: "14px",
                                border: "none",
                                width: "100%",
                                height: selectedLecture?.text.length === 0 ? "100%" : "auto"
                            }
                        }}
                        inputProps={{
                            style: {
                                textAlign: selectedLecture?.text.length > 0 ? "left" : "center",
                                fontSize: selectedLecture?.text.length > 0 ? "18px" : "24px",
                                lineHeight: selectedLecture?.text.length > 0 ? "24px" : "32px",
                            }
                        }}
                        style={{
                            backgroundColor: "#ffffff",
                            overflowY:'auto',
                            height: "calc(100vh - 204px)",
                            marginBottom: "8px",
                            textAlign: "center",
                        }}
                    />
                </Box>}
                {!selectedLecture && <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Typography variant={"h6"}>Add a Lecture</Typography>
                </Box>}
            </SelectedLectureBox>
        </Grid>
        <Grid xs={12} md={2}>
            <LecturesBox>
                <LecturesList spacing={2}>
                    {questions.map((question) => (
                        <LectureItem
                            selected={selectedQuestion === question}
                            onClick={(e) => editQuestionClicked(e, question)}
                            onMouseOut={() => console.log("ON MOOUSE OUT")}>
                            {question.question}
                        </LectureItem>
                    ))}
                </LecturesList>
                <GenerateQuestionsButton
                    isGeneratingQuestions={isGeneratingQuestions}
                    loading={isGeneratingQuestions}
                    disabled={isGeneratingQuestions || selectedLecture?.text.length === 0}
                    spinnerColor={"#333399"}
                    variant={"filled"}
                    onClick={generateQuestionsClicked}
                    >Generate Questions</GenerateQuestionsButton>
            </LecturesBox>
        </Grid>
    </CustomGrid>);
}

export default QuestionBankGridFree;