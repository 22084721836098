import React from "react";
import PropTypes from "prop-types";
import {Box, Button, Link, TextField, Typography} from "@mui/material";
import FormField from "../common/forms/FormField";
import SpinnerButton from "../common/SpinnerButton";

const LoginForm = (props) => {
  const {loginState = {}} = props;
  const { credentials, onChange, onAuthenticate, saving } = props;

  return (
    <React.Fragment>
      <Box style={{
          background: "#fff",
          padding: "20px 20px 0px 20px",
          borderTop: "0px",
          color: "#666",
          textAlign: "left",
          width: "400px"
      }}>
        <form>
          <FormField
              marginBottom={"0px"}
            formElement={
              <TextField
                name="email_address"
                label="Email Address"
                value={credentials.username}
                onChange={onChange}
                placeholder="Enter Email Address"
                error={loginState.inValidFields.emailAddress ? loginState.validationMessages.emailAddress : undefined}
                helperText={loginState.inValidFields.emailAddress ? loginState.validationMessages.emailAddress : undefined}
                fullWidth
              />
            }
          />
            <Box style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "24px"
            }}>
              <FormField
                marginBottom={"4px"}
                formElement={
                  <TextField
                    type="password"
                    name="password"
                    label="Password"
                    value={credentials.password}
                    onChange={onChange}
                    placeholder="Enter password"
                    error={loginState.inValidFields.password ? loginState.validationMessages.password : undefined}
                    helperText={loginState.inValidFields.password ? loginState.validationMessages.password : undefined}
                    fullWidth
                  />
                }
              />
                <Link href={"/forgot_password"}>Forgot Password</Link>
            </Box>
          <SpinnerButton
            loading={saving}
            variant={"contained"}
            disabled={saving}
            value={saving ? "Logging in..." : "Sign In"}
            onClick={onAuthenticate}
            width={"240px"}
              spinnerColor={"#ffffff"}
              spinnerSize={18}
            style={{
                paddingLeft: "32px",
                paddingRight: "32px",
                marginBottom: "24px",
            }}
          >
            Log In
          </SpinnerButton>
        </form>
      </Box>
    </React.Fragment>
  );
};
LoginForm.propTypes = {
  credentials: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAuthenticate: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  errors: PropTypes.object,
};

export default LoginForm;
