import React, { useEffect, useState } from "react";
import {Box, Button, TextField, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import PlusIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import {CustomGrid, LectureItem, LecturesBox, LecturesList, SelectedLectureBox} from "../LecturesPage";
import {styled} from "@mui/material/styles";
import SpinnerButton from "../../common/SpinnerButton";

const AddLectureButton = styled(Button)(({theme}) => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: 70,
    backgroundColor: "#B0E0E6",
    color: "#333399",
    '&:hover': {
        backgroundColor: "#FFA500",
        color: "#FFFFFF"
    }
}));
const GenerateQuestionsButton = styled(SpinnerButton)(({isGeneratingQuestions, theme}) => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: 70,
    backgroundColor: isGeneratingQuestions ? "#C0C0C0" : "#B0E0E6",
    color: isGeneratingQuestions ? "#666699" : "#333399",
    '&:hover': {
        backgroundColor: "#FFA500",
        color: "#FFFFFF"
    }
}));
const QuestionBankGridBasic = (props) => {
    const { lectures, selectedLecture, setSelectedLecture, savingLecture, questions, addLectureClicked, deleteLectureClicked, saveLectureClicked, editQuestionClicked, generateQuestionsClicked, isGeneratingQuestions } = props;
    return (<CustomGrid container spacing={1}>
        <Grid xs={12} md={2}>
            <LecturesBox>
                <LecturesList spacing={2}>
                    {lectures.map((lecture) => (
                        <LectureItem selected={selectedLecture.id === lecture.id} onClick={() => setSelectedLecture(lecture)}>
                            {lecture.document_title}
                        </LectureItem>
                    ))}
                </LecturesList>
                <AddLectureButton
                    disabled={!selectedLecture?._id}
                    variant={"filled"}
                    onClick={() => addLectureClicked(selectedLecture)}
                    startIcon={<PlusIcon style={{
                        fontSize: "24"
                    }} />}
                    style={{
                    }}>Add Lecture</AddLectureButton>
            </LecturesBox>
        </Grid>
        <Grid xs={12} md={8}>
            <SelectedLectureBox>
                {selectedLecture && <Box>
                    <TextField
                        fullWidth
                        style={{
                            marginBottom: "8px",
                            backgroundColor: "#ffffff",
                        }}
                        placeholder={"Give your lecture a name"}
                        value={selectedLecture.document_title}
                        onChange={(event) => {
                            const updatedLecture = Object.assign({}, selectedLecture);
                            updatedLecture.document_title = event.target.value;

                            setSelectedLecture(updatedLecture);
                        }} />
                    <TextField
                        fullWidth id="fullWidth"
                        multiline
                        autoFocus
                        placeholder={"Copy and Paste Lecture Content Here"}
                        value={selectedLecture.text} onChange={(event) => {
                        const updatedLecture = Object.assign({}, selectedLecture);
                        updatedLecture.text = event.target.value;

                        setSelectedLecture(updatedLecture);
                    }}
                        InputProps={{
                            style: {
                                fontSize: "14px",
                                border: "none",
                                width: "100%",
                                height: selectedLecture?.text.length === 0 ? "100%" : "auto"
                            }
                        }}
                        inputProps={{
                            style: {
                                textAlign: selectedLecture?.text.length > 0 ? "left" : "center",
                                fontSize: selectedLecture?.text.length > 0 ? "18px" : "24px",
                                lineHeight: selectedLecture?.text.length > 0 ? "24px" : "32px",
                            }
                        }}
                        style={{
                            backgroundColor: "#ffffff",
                            overflowY:'auto',
                            height: "calc(100vh - 300px)",
                            marginBottom: "8px",
                            textAlign: "center",
                        }}
                    />
                    <Box style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <Box style={{
                            flexGrow: 1,
                        }}>
                            {selectedLecture._id && <Button onClick={() => deleteLectureClicked(selectedLecture)} style={{
                                backgroundColor: "transparent"
                            }}>Delete Lecture</Button>}
                        </Box>
                        <SpinnerButton
                            loading={savingLecture}
                            variant={"contained"}
                            disabled={savingLecture || selectedLecture.text.length === 0}
                            value={savingLecture ? "Saving..." : "Save"}
                            onClick={() => saveLectureClicked(selectedLecture)}
                            width={"240px"}
                            spinnerColor={"#ffffff"}
                            spinnerSize={18}
                            style={{
                                paddingLeft: "32px",
                                paddingRight: "32px",
                                marginBottom: "24px",
                            }}
                        >Save</SpinnerButton>
                    </Box>
                </Box>}
                {!selectedLecture && <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Typography variant={"h6"}>Add a Lecture</Typography>
                </Box>}
            </SelectedLectureBox>
        </Grid>
        <Grid xs={12} md={2}>
            <LecturesBox>
                <LecturesList spacing={2}>
                    {questions.map((question) => (
                        <LectureItem
                            onClick={(e) => editQuestionClicked(e, question)}
                            onMouseOut={() => console.log("ON MOOUSE OUT")}>
                            {question.question}
                        </LectureItem>
                    ))}
                </LecturesList>
                <GenerateQuestionsButton
                    disabled={selectedLecture?._id === undefined}
                    variant={"filled"}
                    onClick={generateQuestionsClicked}
                    startIcon={<RefreshIcon style={{
                        fontSize: "24"
                    }} />}
                    >Generate Questions</GenerateQuestionsButton>
                <GenerateQuestionsButton
                    isGeneratingQuestions={isGeneratingQuestions}
                    loading={isGeneratingQuestions}
                    disabled={isGeneratingQuestions || !selectedLecture?._id}
                    spinnerColor={"#333399"}
                    variant={"filled"}
                    onClick={generateQuestionsClicked}
                >Generate Questions</GenerateQuestionsButton>
            </LecturesBox>
        </Grid>
    </CustomGrid>);
}

export default QuestionBankGridBasic;