import React, { useEffect, useRef, useState } from "react";

import {Button, Box, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';

import {useApi} from "../contexts/ApiProvider";
import {
    useLoaderData,
} from "react-router-dom";
import Header from "../Header";
import { Unstable_Grid2 as Grid } from "@mui/material";
import {usePubNub} from "../contexts/PubNubProvider";
import QuestionBankGridBasic from "./components/QuestionBankGridBasic";
import QuestionBankGridFree from "./components/QuestionBankGridFree";
import ExamBuilderGridBasic from "./components/ExamBuilderGridBasic";
import ExamBuilderGridFree from "./components/ExamBuilderGridFree";
import QuestionDetailsPopover from "./components/QuestionDetailsPopover";
import {useFlashMessage} from "../contexts/FlashMessageProvider";
import handleError from "../common/ErrorHandler";

export const CustomGrid = styled(Grid)(({ theme }) => ({
    margin: "0 20px"
}));
export const LecturesBox = styled(Box)(({ theme}) => ({
    position: "relative",
    padding: "16px 16px 0px 16px",
    backgroundColor: "#D9D9D9",
    height: "calc(100vh - 184px)"
}));
export const LecturesList = styled(Stack)(({ theme}) => ({
    overflowY:'auto',
    height: "calc(100% - 70px)"
}));
export const LectureItem = styled(Button)(({ theme, selected }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "36px 12px",
    lineHeight: "18px",
    color: "#FFFFFF",
    backgroundColor: selected ? "#F55050" :"#F4848F"
}));
export const SelectedLectureBox = styled(Box)(({ theme}) => ({
    padding: "16px",
    backgroundColor: "#D9D9D9",
    overflowY:'hidden',
    height: "calc(100vh - 200px)"
}));
const LecturesPage = () => {
    const [selectedTab, setSelectedTab] = useState("Question Bank");
    const [lectures, setLectures] = useState([]);
    const [selectedLecture, setSelectedLecture] = useState(undefined);
    const [savingLecture, setSavingLecture] = useState(false);
    const [questions, setQuestions] = useState([]);
    const questionCount = useRef(0);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [examText, setExamText] = useState("");
    const letterArray = ["A", "B", "C", "D", "E", "F"];

    const [anchorEl, setAnchorEl] = useState(null);

    const [selectedQuestion, setSelectedQuestion] = useState(undefined);
    const [examGenRequest, setExamGetRequest] = useState(undefined);
    const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);

    const { getLectures, saveLecture, createLecture, deleteLecture, getQuestionsForDocument, saveQuestion, createExamGenRequest } = useApi();

    const currentUser = useLoaderData();
    const { pubNub } = usePubNub();

    const { showErrorMessage, showSuccessMessage } = useFlashMessage();

    useEffect(() => {
        try {
            const loadLectures = async() => {
                const lectures = await getLectures();

                setLectures(lectures);

                if(lectures.length > 0) {
                    const lecture = Object.assign({}, lectures[0]);
                    lecture.text = lecture.text.replace(/\n/g, '');
                    setSelectedLecture(lecture);
                } else {
                    setSelectedLecture({
                        document_title: "",
                        document_type: "lecture",
                        text: ""
                    });
                }
            }
            if(currentUser.account_type === "Paid") {
                loadLectures()
            } else {
                setSelectedLecture({
                    document_title: "",
                    document_type: "lecture",
                    text: ""
                });
            }
        } catch(err) {
            console.log(err);
        }
    }, []);
    useEffect(() => {
        if(!pubNub) { return }
        console.log("ADDING LISTENER");

        const listener = {
            status: (statusEvent) => {
                if (statusEvent.category === "PNConnectedCategory") {
                    console.log("Connected");
                }
            },
            message: (messageEvent) => {
                switch(messageEvent.message.type) {
                    case "question": {
                        const question = messageEvent.message.question;

                        setQuestions(prev => [...prev, Object.assign({}, question, { id: prev.length + 1 })]);
                        questionCount.current += 1;

                        break;
                    }
                    case "finished": {
                        setIsGeneratingQuestions(false);

                        showSuccessMessage(`We've completed generating questions from lecture.  It looks like we came up with ${questionCount.current} questions.  Click a question in the list below to modify the question or answer choices.`);

                        break;
                    }
                    default: {
                        console.log(`Not sure how to handle ${messageEvent.message.type}`);
                    }
                }
            },
            presence: (presenceEvent) => {
                // handle presence
            }
        };
        pubNub.addListener(listener);
    }, [pubNub]);
    useEffect(() => {
        if(!selectedLecture?._id) { return; }
        try {
            const loadQuestions = async() => {
                const questions = await getQuestionsForDocument(selectedLecture);

                setQuestions(questions);
            }
            loadQuestions()
        } catch(err) {
            const message = handleError(err);

            showErrorMessage(message);
        }
    }, [selectedLecture]);
    useEffect(() => {
        let results = []
        for(let i=0; i<selectedQuestions.length; i++) {
            const question = selectedQuestions[i];

            results.push(formatQuestionOutput(question, i+1));
        }
        setExamText(results.join(""));
    }, [selectedQuestions]);
    useEffect(() => {
        if(!examGenRequest) { return; }

        pubNub.subscribe({
            channels: [`exam_gen_questions_${examGenRequest.id}`]
        });
    }, [examGenRequest]);
    const addLectureClicked = () => {
        setSelectedLecture({
            document_title: "",
            document_type: "lecture",
            text: ""
        });
    }
    const saveClicked = async(lecture) => {
        try {
            setSavingLecture(true);
            lecture.text = lecture.text.replace(/\n/g, '');
            lecture.document_type = "lecture";
            if(lecture._id) {
                const results = await saveLecture(lecture);

                setLectures(prev => [...prev.splice(0, prev.findIndex(l => l.id === lecture.id)), results, ...prev.splice(prev.findIndex(l => l.id === lecture.id) + 1)])
            } else {
                if(lecture.document_title.length === 0) {
                    lecture.document_title = `Lecture ${lectures.length + 1}`;
                }
                const results = await createLecture(lecture);

                setLectures(prev => [...prev, results]);
                setSelectedLecture(results);
            }

            showSuccessMessage("Lecture saved.");
        } catch(err) {
            const message = handleError(err);

            showErrorMessage(message);
        } finally {
            setSavingLecture(false);
        }
    }
    const deleteLectureClicked = async(lecture) => {
        try {
            await deleteLecture(lecture);

            const lectureCount = lectures.length - 1;
            setLectures(prev => [...prev.filter(item => item.id !== lecture._id)]);
            if(lectureCount > 0) {
                setSelectedLecture(lectures[0]);
            } else {
                setSelectedLecture({
                    document_title: "",
                    document_type: "lecture",
                    text: ""
                });
            }
            showSuccessMessage("Lecture deleted.");
        } catch(err) {
            const message = handleError(err);

            showErrorMessage(message);
        }
    }
    const generateQuestionsClicked = async() => {
        try {
            if(!selectedLecture.text) { return; }

            setIsGeneratingQuestions(true);
            setQuestions([]);
            questionCount.current = 0;
            setSelectedQuestion([]);

            const request = {
                documents: selectedLecture.id ? [selectedLecture.id] : [],
                number_of_multichoice_questions: 3,
                number_of_short_answer_questions: 1,
            }
            if(!selectedLecture.id) {
                request.text = selectedLecture.text
            }
            const result = await createExamGenRequest(request)

            setExamGetRequest(result);

            showSuccessMessage("Awesome!  We'll have ChatGPT start generating questions from your lecture material.  Depending on the length of you lecture this could take up to 2 minutes.");
        } catch(err) {
            const message = handleError(err);

            showErrorMessage(message);
        }
    }
    const editQuestionClicked = async(event, question) => {
        setSelectedQuestion(question);
        setAnchorEl(event.currentTarget);
    }
    const saveQuestionClicked = async() => {
        try {
            debugger;
            if(selectedLecture.id) {
                await saveQuestion(selectedLecture, selectedQuestion);
            }
            setQuestions(prev => [...prev.splice(0, prev.findIndex(q => q.id === selectedQuestion.id)), selectedQuestion, ...prev.splice(prev.findIndex(q => q.id === selectedQuestion.id) + 1)]);
            setAnchorEl(null);

            showSuccessMessage("Question updated.")
        } catch(err) {
            const message = handleError(err);

            showErrorMessage(message);
        }
    }
    const questionClicked = async(question) => {
        if(selectedQuestions.find((item) => (item.id === question.id))) {
            setSelectedQuestions(prev => [...prev.filter(item => item.id !== question.id)]);
        } else {
            setSelectedQuestions(prev => [...prev, question]);
        }
    }
    const formatQuestionOutput = (question, question_number) => {
        let formattedQuestion = `${question_number}. ${question.question}`;

        switch(question.question_type) {
            case "multiple_choice": {
                formattedQuestion += "\n";
                for (let i = 0; i < question.possible_answers.length; i++) {
                    const answer = question.possible_answers[i];

                    formattedQuestion += "\n";
                    formattedQuestion += "    ";
                    formattedQuestion += `${letterArray[i]}. ${answer}`;
                }
                formattedQuestion += "\n";
                formattedQuestion += "\n";

                break;
            }
            case "short_answer": {
                formattedQuestion += "\n";
                formattedQuestion += "\n";
                formattedQuestion += "\n";
                formattedQuestion += "\n";
                formattedQuestion += "\n";
                formattedQuestion += "\n";

                break;
            }
            default: {
                console.log(`I am not sure how to handle ${question.question_type} Questions.`);
            }
        }
        return formattedQuestion;
    }
    return (
        <Box>
           <Header />
            <Box>
                <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginLeft: "40px",
                    marginRight: "40px",
                    marginBottom: "4px"
                }}>
                        <Button variant={"text"}
                                onClick={() => setSelectedTab("Question Bank")}
                            style={{
                            backgroundColor: "transparent",
                            color: "#000000",
                            fontStyle: "normal",
                            fontWeight: selectedTab === "Question Bank" ? "500" : "100",
                            fontSize: "24px",
                            textDecoration: selectedTab === "Question Bank" ? "underline" : "none"
                        }}>Question Bank</Button>
                    <Box style={{
                        backgroundColor: "#000000",
                        width: "2px",
                        height: "36px",
                        marginLeft: "12px",
                        marginRight: "12px"
                    }} />
                    <Button variant={"text"}
                            onClick={() => setSelectedTab("Exam Builder")}
                    style={{
                        backgroundColor: "transparent",
                        color: "#000000",
                        fontStyle: "normal",
                        fontWeight: selectedTab === "Exam Builder" ? "500" : "100",
                        fontSize: "24px",
                        textDecoration: selectedTab === "Exam Builder" ? "underline" : "none",
                    }}>Exam Builder</Button>
                </Box>
                {selectedTab === "Question Bank" && currentUser.account_type === "Paid" && <QuestionBankGridBasic
                    lectures={lectures}
                    selectedLecture={selectedLecture}
                    setSelectedLecture={setSelectedLecture}
                    questions={questions.filter(question => question.document === selectedLecture.id)}
                    selectedQuestion={selectedQuestion}
                    addLectureClicked={addLectureClicked}
                    deleteLectureClicked={deleteLectureClicked}
                    saveLectureClicked={saveClicked}
                    savingLecture={savingLecture}
                    editQuestionClicked={editQuestionClicked}
                    generateQuestionsClicked={generateQuestionsClicked}
                    isGeneratingQuestions={isGeneratingQuestions}
                />}
                {selectedTab === "Question Bank" && currentUser.account_type !== "Paid" && <QuestionBankGridFree
                    lectures={lectures}
                    selectedLecture={selectedLecture}
                    setSelectedLecture={setSelectedLecture}
                    questions={questions}
                    selectedQuestion={selectedQuestion}
                    addLectureClicked={addLectureClicked}
                    deleteLectureClicked={deleteLectureClicked}
                    saveLectureClicked={saveClicked}
                    savingLecture={savingLecture}
                    editQuestionClicked={editQuestionClicked}
                    generateQuestionsClicked={generateQuestionsClicked}
                    isGeneratingQuestions={isGeneratingQuestions}
                />}
                {selectedTab === "Exam Builder" && currentUser.account_type === "Paid" && <ExamBuilderGridBasic
                    lectures={lectures}
                    selectedLecture={selectedLecture}
                    questions={questions}
                    selectedQuestions={selectedQuestions}
                    questionClicked={questionClicked}
                    setSelectedLecture={setSelectedLecture}
                    examText={examText}
                    setExamText={setExamText}
                />}
                {selectedTab === "Exam Builder" && currentUser.account_type !== "Paid" && <ExamBuilderGridFree
                    questions={questions}
                    selectedQuestions={selectedQuestions}
                    questionClicked={questionClicked}
                    examText={examText}
                    setExamText={setExamText}
                />}
            </Box>
            <QuestionDetailsPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} selectedQuestion={selectedQuestion} setSelectedQuestion={setSelectedQuestion} saveQuestionClicked={saveQuestionClicked} />
        </Box>
    );
};

export default LecturesPage;
