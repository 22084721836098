import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Box, Paper, TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

import {useAuth} from "../contexts/AuthProvider";
import {useApi} from "../contexts/ApiProvider";
import { useNavigate } from "react-router-dom";
import Header from "../Header";

const QuestionBankPage = () => {
    const [accumulator, setAccumulator] = useState({
        current_page: 0,
        page_size: 20,
        total_items: 0
    });
    const [questions, setQuestions] = useState([]);
    const [selectedLecture, setSelectedLecture] = useState(undefined);

    const { getQuestionBank } = useApi();

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingTop: "36px",
        paddingBottom: "36px"
    }));

    useEffect(() => {
        try {
            const loadQuestionBank = async() => {
                const questionBank = await getQuestionBank(accumulator.current_page, accumulator.page_size);

                console.log(questionBank);
                setQuestions(questionBank.questions);
                setAccumulator(prev => Object.assign({}, prev, {
                    total_items: questionBank.accumulator.total_items,
                    current_page: questionBank.accumulator.current_page
                }))
            }
            loadQuestionBank()
        } catch(err) {
            console.log(err);
        }
    }, [accumulator.current_page]);

    return (
        <Box>
           <Header />
            <Box style={{
                marginLeft: "10%",
                marginRight: "10%",
            }}>
                <Typography variant={"h2"}>Question Bank</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Question</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Answers</TableCell>
                                <TableCell>Correct Answer</TableCell>
                                <TableCell align="right">Source</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {questions.map((question) => (
                                <TableRow
                                    key={question.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {question.question}
                                    </TableCell>
                                    <TableCell>{question.question_type}</TableCell>
                                    <TableCell>{question.possible_answers.map((answer) => (
                                        <div>{answer}</div>
                                    ))}</TableCell>
                                    <TableCell>{(question.question_type === "multiple_choice") ? question.correct_answer : ""}</TableCell>
                                    <TableCell align="right" style={{
                                        whiteSpace: "no-wrap",
                                        wordWrap: "no-wrap"
                                    }}>{question.document.document_title}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={accumulator.total_items}
                        page={accumulator.current_page}
                        onPageChange={() => {
                            setAccumulator(prev => Object.assign({}, prev, { current_page: prev.current_page + 1}))
                        }}
                        rowsPerPage={accumulator.page_size}
                        onRowsPerPageChange={() => {}}
                    />
                </TableContainer>
            </Box>
        </Box>
    );
};

export default QuestionBankPage;
