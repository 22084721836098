import {redirect, useLocation, useSearchParams} from "react-router-dom";
import {useApi} from "./contexts/ApiProvider";

const OAuthRedirectLoader = async () => {
    const { me } = useApi();
    const { search } = window.location;

    try {
        const params = new URLSearchParams(search);

        const access_token = params.get("access_token");

        if(!access_token) {
            return redirect("/login");
        }
        localStorage.setItem("access_token", access_token);
        const user = await me();
        if(!user) {
            return redirect("/login");
        }
        return user;
    } catch(err) {
        console.log(err);
    }
};
export default OAuthRedirectLoader;