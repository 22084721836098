import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {Button, Box, MenuItem, Paper, Stack, TextField, Typography, Link, Unstable_Grid2 as Grid} from "@mui/material";
import { styled } from '@mui/material/styles';

import {useApi} from "../contexts/ApiProvider";
import { usePubNub } from "../contexts/PubNubProvider";
import Header from "../Header";
import PlusIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import {CustomGrid, LectureItem, LecturesBox, LecturesList, SelectedLectureBox} from "../lectures/LecturesPage";


const GenerateExamPage = () => {
    const [lectures, setLectures] = useState([]);
    const [generatedQuestions, setGeneratedQuestions] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [selectedLecture, setSelectedLecture] = useState(undefined);
    const [examGenRequests, setExamGetRequests] = useState([])
    const [examGenRequest, setExamGetRequest] = useState(undefined);
    const [selectedExamGenRequest, setSelectedExamGenRequest] = useState(undefined);
    const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);
    const [examText, setExamText] = useState("");
    const letterArray = ["A", "B", "C", "D", "E", "F"];

    const { getLectures, createExamGenRequest, getExamGenRequests, getExamGenRequest, getQuestionsForDocument } = useApi();
    const { pubNub } = usePubNub();

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#1A2027',
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingTop: "36px",
        paddingBottom: "36px",
        margin: "16px !important"
    }));
    const SelectedItem = styled(Paper)(({ theme }) => ({
        backgroundColor: '#0000FF',
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingTop: "36px",
        paddingBottom: "36px",
        margin: "16px !important"
    }));
    useEffect(() => {
        if(!selectedLecture) { return; }
        try {
            const loadQuestions = async() => {
                lectures.map(async(lecture) => {
                    if(lecture.id === selectedLecture) {
                        const questions = await getQuestionsForDocument(lecture);

                        setGeneratedQuestions(questions);
                    }
                })
            }
            loadQuestions()
        } catch(err) {
            console.log(err);
        }
    }, [selectedLecture]);
    useEffect(() => {
        if(!pubNub) { return }
        console.log("ADDING LISTENER");

        const listener = {
            status: (statusEvent) => {
                if (statusEvent.category === "PNConnectedCategory") {
                    console.log("Connected");
                }
            },
            message: (messageEvent) => {
                console.log(messageEvent.message);

                switch(messageEvent.message.type) {
                    case "question": {
                        console.log("PROCESSING QUESTION", messageEvent.message.question);

                        const question = messageEvent.message.question;

                        let formattedQuestion = `${question.question_number}. ${question.question}`;

                        switch(question.question_type) {
                            case "multiple_choice": {
                                setGeneratedQuestions(prev => [...prev, question]);

                                formattedQuestion += "\n";
                                for (let i = 0; i < question.answers.length; i++) {
                                    const answer = question.answers[i];

                                    console.log(answer);

                                    formattedQuestion += "\n";
                                    formattedQuestion += "    ";
                                    formattedQuestion += `${letterArray[i]}. ${answer}`;
                                }
                                formattedQuestion += "\n";
                                formattedQuestion += "\n";

                                break;
                            }
                            case "short_answer": {
                                formattedQuestion += "\n";
                                formattedQuestion += "\n";
                                formattedQuestion += "\n";
                                formattedQuestion += "\n";
                                formattedQuestion += "\n";
                                formattedQuestion += "\n";

                                break;
                            }
                            default: {
                                console.log(`I am not sure how to handle ${question.question_type} Questions.`);
                            }
                        }

                        console.log("SETTING EXAM TEXT TO", examText + " " + formattedQuestion);
                        setExamText(prev => prev + formattedQuestion)
                        break;
                    }
                    case "finished": {
                        setIsGeneratingQuestions(false);

                        break;
                    }
                    default: {
                        console.log(`Not sure how to handle ${messageEvent.message.type}`);
                    }
                }
            },
            presence: (presenceEvent) => {
                // handle presence
            }
        };
        pubNub.addListener(listener);
    }, [pubNub]);
    useEffect(() => {
        try {
            const loadLectures = async() => {
                const lectures = await getLectures();

                setLectures(lectures);

                if(lectures.length > 0) {
                    setSelectedLecture(lectures[0].id);
                }
            }
            loadLectures()
        } catch(err) {
            console.log(err);
        }
    }, []);
    useEffect(() => {
        try {
            const loadExamGenRequests = async() => {
                const examGenRequests = await getExamGenRequests();

                setExamGetRequests(examGenRequests);
            }
            loadExamGenRequests()
        } catch(err) {
            console.log(err);
        }
    }, []);
    useEffect(() => {
        if(!examGenRequest) { return; }

        pubNub.subscribe({
            channels: [`exam_gen_questions_${examGenRequest.id}`]
        });
    }, [examGenRequest])
    useEffect(() => {
        let results = []
        for(let i=0; i<selectedQuestions.length; i++) {
            const question = selectedQuestions[i];

            results.push(formatQuestionOutput(question, i+1));
        }
        setExamText(results.join());
    }, [selectedQuestions]);
    const questionClicked = async(question) => {
        debugger;
        if(selectedQuestions.find((item) => (item.id === question.id))) {
            setSelectedQuestions(prev => [...prev.filter(item => item.id !== question._id)]);
        } else {
            setSelectedQuestions(prev => [...prev, question]);
        }
    }
    const generateQuestionsClicked = async() => {
        try {
            if(selectedQuestions.length === 0) { return; }

            setIsGeneratingQuestions(true);
            console.log("SELECtED", selectedQuestions);
            const request = {
                documents: selectedQuestions,
                number_of_multichoice_questions: 3,
                number_of_short_answer_questions: 1,
            }
            const result = await createExamGenRequest(request)

            console.log("CREATE REQUEST", result);
            setExamGetRequest(result);
        } catch(err) {
            console.log(err);
        }
    }
    const formatQuestionOutput = (question, question_number) => {
        let formattedQuestion = `${question_number}. ${question.question}`;

        switch(question.question_type) {
            case "multiple_choice": {
                setGeneratedQuestions(prev => [...prev, question]);

                formattedQuestion += "\n";
                for (let i = 0; i < question.possible_answers.length; i++) {
                    const answer = question.possible_answers[i];

                    console.log(answer);

                    formattedQuestion += "\n";
                    formattedQuestion += "    ";
                    formattedQuestion += `${letterArray[i]}. ${answer}`;
                }
                formattedQuestion += "\n";
                formattedQuestion += "\n";

                break;
            }
            case "short_answer": {
                formattedQuestion += "\n";
                formattedQuestion += "\n";
                formattedQuestion += "\n";
                formattedQuestion += "\n";
                formattedQuestion += "\n";
                formattedQuestion += "\n";

                break;
            }
            default: {
                console.log(`I am not sure how to handle ${question.question_type} Questions.`);
            }
        }

        return formattedQuestion;
    }

    return(<Box>
        <Header />
        <Box>
            <Box style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "32px",
                marginRight: "32px",
                marginBottom: "4px"
            }}>
                <Box style={{
                    flexGrow: 1
                }}>
                    <Typography variant={"h6"}>Exam Builder</Typography>
                </Box>
                <Link href={"/Lectures"} style={{
                    fontFamily: 'Inter',
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "28px",
                    lineHeight: "36px",
                }}>Back to Question Bank</Link>
            </Box>
            <CustomGrid container spacing={1}>
                <Grid xs={12} md={3}>
                    <LecturesBox>
                        <TextField
                            displayEmpty={false}
                            fullWidth
                            select
                            style={{
                                backgroundColor: "#FFFFFF",
                                marginBottom: "12px",
                            }}
                           onChange={(e) => {
                               lectures.map((lecture) => {
                                   if(lecture.id === e.target.value) {
                                       setSelectedLecture(lecture.id)
                                   }
                               });
                           }}
                        >
                            {lectures.map((lecture) => (
                                <MenuItem key={lecture.id} value={lecture.id}>
                                    {lecture.document_title}
                                </MenuItem>
                            ))}
                        </TextField>
                        <LecturesList spacing={2}>
                            {generatedQuestions.map((question) => (
                                <LectureItem selected={selectedQuestions.find((item) => (item.id === question.id))} onClick={() => questionClicked(question)}>
                                    {question.question}
                                </LectureItem>
                            ))}
                        </LecturesList>
                    </LecturesBox>
                </Grid>
                <Grid xs={12} md={9}>
                    <SelectedLectureBox>
                            <TextField
                                fullWidth
                                id="fullWidth"
                                multiline
                                placeholder={"Questions will be displayed here"} value={examText}
                                style={{
                                    backgroundColor: "#ffffff",
                                    overflowY:'scroll',
                                    height: "calc(100%)",
                                    marginBottom: "8px",
                                }}
                             onChange={(event) => {
                                setExamText(event.target.value);
                            }}
                        />
                    </SelectedLectureBox>
                </Grid>
            </CustomGrid>
        </Box>
    </Box>)
}

export default GenerateExamPage;
