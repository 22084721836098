import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {Box, Button, TextField, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import SendImage from "../../../images/send.png"

const MarketingBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
        marginBottom: "40px"
    },
    [theme.breakpoints.up('md')]: {
        height: "160px"
    },
}));
const InterestedBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down('md')]: {
        alignItems: "center"
    },
    [theme.breakpoints.up('md')]: {
        alignItems: "flex-end"
    },
}));
const EmailBox = styled(Box)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        marginLeft: "20%",
        marginRight: "20%"
    },
    [theme.breakpoints.up('md')]: {
        width: "100%"
    },
}));
const SendImageBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down('md')]: {
        alignItems: "center"
    },
    [theme.breakpoints.up('md')]: {
        alignItems: "flex-start"
    },
}));

const MarketingView = (props) => {
    const [emailAddress, setEmailAddress] = useState("");
    const { emailInquiryState, sendClicked } = props;

    const emailInquiryClicked = () => {
        sendClicked(emailAddress);
    }

    return (
        <MarketingBox>
            {!emailInquiryState.isValid && <Typography variant={"error"} style={{
                marginBottom: "24px"
            }}>{emailInquiryState.validationMessage}</Typography>}
            {emailInquiryState.isError && <Typography variant={"error"} style={{
                marginBottom: "24px"
            }}>{emailInquiryState.errorMessage}</Typography>}
            {emailInquiryState.isSent && <Typography variant={"success"} style={{
                marginBottom: "24px"
            }}>Thank you.  We'll be in touch soon.</Typography>}
            <Grid container spacing={0} style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
            }}>
                <Grid xs={12} md={5}>
                    <InterestedBox>
                        <Typography variant={"h6"} style={{
                            marginRight: "20px"
                        }}>
                            Interested in learning more?
                        </Typography>
                    </InterestedBox>
                </Grid>
                <Grid xs={12} md={4}>
                    <EmailBox>
                        <TextField id={"emailAddress"} value={emailAddress}
                                   onChange={(e) => { setEmailAddress(e.target.value )}}
                                   style={{
                                       width: "100%",
                                   }} placeholder={"your email address"}
                        />
                    </EmailBox>
                </Grid>
                <Grid xs={12} md={3}>
                    <SendImageBox>
                        <Button variant="text" size="small" onClick={emailInquiryClicked} style={{
                            backgroundColor: "transparent"
                        }}>
                            <img
                                src={SendImage}
                                alt=""
                                onClick={() => {}}
                                id="secret_folder"
                            />
                        </Button>
                    </SendImageBox>
                </Grid>
            </Grid>
        </MarketingBox>
    )
}
export default MarketingView;