import React, { useState } from "react";
import ForgotPasswordForm from './ForgotPasswordForm';
import AuthLayout from "./AuthLayout";
import AuthBox from "./AuthBox";
import {Link, Typography} from "@mui/material";
import {useApi} from "../contexts/ApiProvider";
import {validateEmail} from "./RegisterPage";
import {useFlashMessage} from "../contexts/FlashMessageProvider";
import handleError from "../common/ErrorHandler";

const ForgotPasswordPage = (props) => {
  const { forgotPassword } = useApi();

  const [emailAddress, setEmailAddress] = useState("");
  const [saving, setSaving] = useState(false);
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();

  const [forgotPasswordState, setForgotPasswordState] = useState({
        isInValid: false,
        inValidFields: {
            emailAddress: false,
        },
        validationMessages: {
            emailAddress: "Email Address is required"
        },
        validationMessage: "Please correct the issues below and submit again.",
        isError: false,
      isSuccess: false,
        isEnabled: true,
        errorMessage: "An error occurred"
    });

  const onSubmit = async() => {
      try {
          setSaving(true);

          let isEmailValid = true;

          if(!validateEmail(emailAddress)) {
              isEmailValid = false;
          }

          if(!isEmailValid) {
              setForgotPasswordState(prev => Object.assign({}, prev, {
                  isInValid: !isEmailValid,
                  inValidFields: {
                      emailAddress: !isEmailValid,
                  },
                  isError: false,
                  isSuccess: true,
                  errorMessage: ""
              }));

              return;
          }
          await forgotPassword(emailAddress);

          setForgotPasswordState(prev => Object.assign({}, prev, {
              isInValid: false,
              inValidFields: {
                  emailAddress: false,
              },
              isError: false,
              isSuccess: true,
              errorMessage: ""
          }));
          setEmailAddress("");

          showSuccessMessage("Thanks.  We sent an email with a password reset link.");
      } catch(err) {
          const message = handleError(err);

          showErrorMessage(message);

          setForgotPasswordState(prev => Object.assign({}, prev, {
              isInValid: false,
              inValidFields: {
                  emailAddress: false,
              },
              isError: true,
              isSuccess: false,
              errorMessage: "Sorry, an error occurred.  Please try again."
          }));
      } finally {
          setSaving(false);
      }
  }
  return (
      <AuthLayout heading={"Forgot Password"}>
          <AuthBox>
              <ForgotPasswordForm
                  email_address={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  onSubmit={onSubmit}
                  saving={saving}
                  formState={forgotPasswordState}
              />
          </AuthBox>
          <Typography variant={"body1"} component={"h2"}>
              <Link href="/login">Back to Sign In</Link>
          </Typography>
      </AuthLayout>
  );
}

export default ForgotPasswordPage;
